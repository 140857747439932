import { TEAM_ACTION_TYPES } from './TeamActions';

const initialState = {
  teams: [],
  myTeams: {},
  teamDetail: {},
  teamPlayers: [],
  teamMembers: [],
  teamRoles: [],
  positions: [],
  error: '',
};

export default function team(state = initialState, action) {
  switch (action.type) {
    case TEAM_ACTION_TYPES.FETCH_TEAMS_SUCCESS:
      return Object.assign({}, state, {
        teams: action.payload,
      });
    case TEAM_ACTION_TYPES.FETCH_MY_TEAMS_SUCCESS:
      return Object.assign({}, state, {
        myTeams: action.payload,
      });
    case TEAM_ACTION_TYPES.FETCH_TEAM_SUCCESS:
      return Object.assign({}, state, {
        teamDetail: action.payload,
      });
    case TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS:
      return Object.assign({}, state, {
        teamPlayers: action.payload,
      });
    case TEAM_ACTION_TYPES.FETCH_TEAM_MEMBERS_SUCCESS:
      return Object.assign({}, state, {
        teamMembers: action.payload,
      });
    case TEAM_ACTION_TYPES.FETCH_TEAM_ROLES_SUCCESS:
      return Object.assign({}, state, {
        teamRoles: action.payload,
      });
    case TEAM_ACTION_TYPES.FETCH_TEAM_POSITION_SUCCESS:
      return Object.assign({}, state, {
        positions: action.payload,
      });
    case TEAM_ACTION_TYPES.TEAM_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
