import React, { useEffect, useState } from 'react';
import { fetchWellnessTeam } from './StatsActions';
import { teamWellnessSelector } from './StatsSelectors';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Table from './Table';
import LoadingSpinner from '../shared/LoadingSpinner';

const WellnessStats = ({ id, fetchWellnessTeam, teamWellness, goToProfile }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchWellnessTeam(id).then(() => setLoading(false));
  }, []);


  return (
    <div>
      {loading ? (
        <LoadingSpinner/>
      ) : (
        <Table allMembersWellness={teamWellness} goToProfile={goToProfile}/>
      )
      }
    </div>
  );
};

const mapStateToProps = state => ({
  teamWellness: teamWellnessSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWellnessTeam,
    },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(WellnessStats);