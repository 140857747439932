import { fetchMiddleware } from '../../utils/fetchMiddleware';
import {
  DEFAULT_DAYS,
  DEFAULT_DAYS_AL,
  DEFAULT_DAYS_MONOTONY,
  DEFAULT_OFFSET_CL,
  DEFAULT_WEEKS,
  DEFAULT_START_WEEK,
} from '../../const/chart';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const STATS_ACTION_TYPES = {
  FETCH_TEAM_RPE_SUCCESS: 'FETCH_TEAM_RPE_SUCCESS',
  FETCH_TEAM_RPE_WEEK_SUCCESS: 'FETCH_TEAM_RPE_WEEK_SUCCESS',
  FETCH_TEAM_RPE_BY_POSITION_SUCCESS: 'FETCH_TEAM_RPE_BY_POSITION_SUCCESS',
  FETCH_WELLNESS_TEAM: 'FETCH_WELLNESS_TEAM',
};

export const fetchTeamRPE = (
  idTeam,
  days = DEFAULT_DAYS.VALUE,
  offsetCl = DEFAULT_OFFSET_CL.VALUE,
  cantDaysAl = DEFAULT_DAYS_AL.VALUE,
  cantDaysMonotony = DEFAULT_DAYS_MONOTONY.VALUE
) => dispatch => {
  return fetchMiddleware(
    'GET',
    `/evaluation/rpe_team/?idTeam=${idTeam}&days=${days}&offsetCl=${offsetCl}&cantDaysAl=${cantDaysAl}&cantDaysMonotony=${cantDaysMonotony}`
  ).then(
    json =>
      dispatch({
        type: STATS_ACTION_TYPES.FETCH_TEAM_RPE_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const fetchTeamRPEWeek = (
  idTeam,
  cantWeeks = DEFAULT_WEEKS.VALUE,
  startWeek = DEFAULT_START_WEEK.VALUE
) => dispatch => {
  return fetchMiddleware(
    'GET',
    `/evaluation/weekly_rpe_team/?idTeam=${idTeam}&cantWeeks=${cantWeeks}&startWeek=${startWeek}`
  ).then(
    json =>
      dispatch({
        type: STATS_ACTION_TYPES.FETCH_TEAM_RPE_WEEK_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const fetchTeamRPEByPosition = (
  idTeam,
  position,
  days = DEFAULT_DAYS.VALUE,
  offsetCl = DEFAULT_OFFSET_CL.VALUE,
  cantDaysAl = DEFAULT_DAYS_AL.VALUE,
  cantDaysMonotony = DEFAULT_DAYS_MONOTONY.VALUE
) => dispatch => {
  return fetchMiddleware(
    'GET',
    `/evaluation/rpe_team/?idTeam=${idTeam}&days=${days}&offsetCl=${offsetCl}&cantDaysAl=${cantDaysAl}&cantDaysMonotony=${cantDaysMonotony}&position=${position}`
  ).then(json => json, error => actionErrorHandler(error, dispatch));
};

export const fetchWellnessTeam = (id, date) => dispatch => {
  return fetchMiddleware(
    'GET',
    `/evaluation/wellness_team/?idTeam=${id}`
  ).then(
    json =>
      dispatch({
        type: STATS_ACTION_TYPES.FETCH_WELLNESS_TEAM,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};
