import {
  gatekeeperFetchMiddleware,
  publicFetchMiddleware,
} from '../../utils/fetchMiddleware';
import defaultInstance from '../../utils/defaultInstance';

export const AUTH_ACTION_TYPES = {
  GOOGLE_AUTH_LOADING: 'GOOGLE_AUTH_LOADING',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  GOOGLE_LOGOUT_SUCCESS: 'GOOGLE_LOGOUT_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',
  USER_UNAUTHORIZED: 'USER_UNAUTHORIZED',
};

export const authRequested = value => dispatch => {
  return dispatch({
    type: AUTH_ACTION_TYPES.GOOGLE_AUTH_LOADING,
    payload: value,
  });
};

export const loginSuccess = data => dispatch => {
  return dispatch({
    type: AUTH_ACTION_TYPES.AUTH_SUCCESS,
    payload: data,
  })
};

export const loginWithGoogle = (accessToken, code) => dispatch => {
  return gatekeeperFetchMiddleware('POST', `/login/google/?backend=${defaultInstance()}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ access_token: accessToken, code }),
  })
    .then(json => {
      if (json.key) {
        localStorage.setItem('token', json.key);
        return Promise.resolve(json);
      } else {
        return Promise.reject('El usuario no tiene acceso al sistema');
      }
    })
    .then(
      json =>
        dispatch({
          type: AUTH_ACTION_TYPES.AUTH_SUCCESS,
          payload: json,
        }),
      error => dispatch({ type: AUTH_ACTION_TYPES.AUTH_ERROR, msg: error }),
    );
};

export const auth = () => dispatch => {
  return publicFetchMiddleware('GET', '/rest-auth/user/', {}, true).then(
    json =>
      dispatch({
        type: AUTH_ACTION_TYPES.AUTH_SUCCESS,
        payload: json,
      }),
    error => {
      localStorage.removeItem('token');
      dispatch({ type: AUTH_ACTION_TYPES.USER_UNAUTHORIZED, msg: error });
    },
  );
};

export const logout = () => dispatch => {
  return publicFetchMiddleware('GET', '/rest-auth/logout/', {}, true)
    .then(() => {
      localStorage.removeItem('token');
    })
    .then(
      () =>
        dispatch({
          type: AUTH_ACTION_TYPES.GOOGLE_LOGOUT_SUCCESS,
          payload: {},
        }),
      error => dispatch({ type: AUTH_ACTION_TYPES.AUTH_ERROR, msg: error }),
    );
};

export const resetAuthError = () => ({
  type: AUTH_ACTION_TYPES.AUTH_ERROR,
  msg: '',
});

export const setAuthError = msg => ({
  type: AUTH_ACTION_TYPES.AUTH_ERROR,
  msg: msg,
});