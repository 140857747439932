import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable, { MTableToolbar } from 'material-table';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { format } from 'date-fns';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AddMemberDialog from '../AddMemberDialog';
import * as personActions from '../../person/PersonActions';
import * as teamActions from '../../team/TeamActions';
import {
  positionsSelector,
  teamDetailSelector,
  teamMembersSelector,
} from '../../team/TeamSelectors';
import { translations } from '../../../const/table-localization';
import ImageUploadDialog from '../../upload/ImageUploadDialog';
import { BASE_URL } from '../../../const/config';
import defaultImage from '../../../utils/defaultImage';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
  categorySelect: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const SquadDetail = ({
  match: {
    params: { id },
  },
  fetchTeam,
  resetTeam,
  teamDetail,
  fetchTeamMembers,
  resetTeamMembers,
  teamMembers,
  history,
  deleteMember,
  fetchPositions,
  positions,
  updateMember,
  uploadPersonImage,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const [fetch, setFetch] = useState(null);

  const tableOptions = {
    search: true,
    sorting: false,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize,
    paging: false,
  };

  useEffect(() => {
    // TODO cuando el fetch de players funcione, obtener el nombre equipo de esa respuesta y no hacer el fetchTeam(id)
    resetTeam();
    resetTeamMembers();
    fetchTeam(id);
    fetchTeamMembers(id);
    fetchPositions();
  }, [fetch]);
  const columns = [
    {
      field: 'image',
      title: '',
      editable: 'never',
      render: rowData => {
        return (
          <img
            src={
              rowData.person && rowData.person.image
                ? BASE_URL + rowData.person.image
                : defaultImage()
            }
            style={{ width: 50, borderRadius: '50%' }}
          />
        );
      },
    },
    {
      title: 'Nombre',
      field: 'person.firstName',
      cellStyle: {
        width: '250px',
      },
      editable: 'never',
    },
    {
      title: 'Apellido',
      field: 'person.lastName',
      cellStyle: {
        width: '250px',
      },
      editable: 'never',
    },
    {
      title: 'Rol',
      field: 'roleTeam.description',
      cellStyle: {
        width: '250px',
      },
      editable: 'never',
    },
    {
      title: 'Posición',
      field: 'player.position.id',
      render: rowData =>
        rowData.player && rowData.player.position
          ? rowData.player.position.description
          : '-',
      cellStyle: {
        width: '250px',
      },
      editable: (column, rowData) => !!rowData.player,
      editComponent: props => {
        return (
          <TextField
            select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            className={classes.select}
          >
            {positions.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.description}
              </MenuItem>
            ))}
          </TextField>
        );
      },
    },
    {
      title: 'Número',
      field: 'player.number',
      emptyValue: '-',
      editable: (column, rowData) => !!rowData.player,
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Fecha fin',
      field: 'endDate',
      render: rowData =>
        format(new Date(moment(rowData.endDate).toISOString()), 'dd-MM-yyyy'),
      type: 'date',
      cellStyle: {
        width: '200px',
      },
    },
  ];

  const goToPlayerDetail = (id, personID) => {
    history.push(`/profile/${id}/${personID}`);
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const onMemberSubmit = () => {
    fetchTeamMembers(id);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});

  const onImageUploadSubmit = image => {
    uploadPersonImage(selectedMember.person.id, image).then(() => {
      fetchTeamMembers(id);
    });
    setImageUploadDialogOpen(false);
  };
  const onImageUploadClose = () => {
    setImageUploadDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      {selectedMember.person && (
        <ImageUploadDialog
          open={imageUploadDialogOpen}
          onSubmit={onImageUploadSubmit}
          onClose={onImageUploadClose}
          fileName={`profile_${selectedMember.person.id}`}
        />
      )}
      <AddMemberDialog
        onSubmit={onMemberSubmit}
        open={dialogOpen}
        onClose={handleClose}
      />
      <MaterialTable
        title={
          <Typography variant="h5" color="textPrimary">
            {teamDetail.name}
          </Typography>
        }
        onChangeRowsPerPage={value => {
          setPageSize(value);
        }}
        columns={columns}
        options={tableOptions}
        localization={translations}
        data={teamMembers}
        actions={[
          {
            icon: 'person_add',
            tooltip: 'Agregar miembro',
            isFreeAction: true,
            iconProps: {
              color: 'primary',
            },
            onClick: event => handleClickOpen(),
          },
          rowData => {
            return rowData.player
              ? {
                  icon: 'list_alt',
                  tooltip: 'Ver ficha',
                  disable: false,
                  onClick: (event, rowData) => {
                    goToPlayerDetail(rowData.id, rowData.person.id);
                  },
                }
              : {
                  hidden: true,
                };
          },
          {
            onClick: (event, rowData) => {
              setSelectedMember(rowData);
              setImageUploadDialogOpen(true);
            },
            icon: 'add_a_photo',
            tooltip: 'Cargar foto',
          },
        ]}
        editable={{
          isDeletable: rowData => true,
          isEditable: rowData => true,
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              let newMember = {
                id: oldData.id,
                team: teamDetail.id,
                roleTeam: oldData.roleTeam.id,
                person: oldData.person.id,
                initialDate: oldData.initialDate,
                endDate: format(
                  new Date(moment(newData.endDate).toISOString()),
                  'yyyy-MM-dd'
                ),
              };
              if (oldData.player) {
                if (newData.player.position) {
                  newMember = {
                    ...newMember,
                    position: newData.player.position.id,
                  };
                }
                if (newData.player.number) {
                  newMember = {
                    ...newMember,
                    number: newData.player.number,
                  };
                }
              }
              updateMember(newMember).then(res => {
                fetchTeamMembers(id);
                resolve();
              });
            }),
          onRowDelete: rowData =>
            new Promise((resolve, reject) => {
              deleteMember(rowData.id).then(res => {
                fetchTeamMembers(id);
                resolve();
              });
            }),
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  teamDetail: teamDetailSelector(state),
  teamMembers: teamMembersSelector(state),
  positions: positionsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...teamActions, ...personActions }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SquadDetail);
