import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnthropometricDataForm from './AnthropometricDataForm'
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import * as anthropometricDataActions from './AnthropometricDataActions';

export const AnthropometricDataFormWrapper = ({
    anthropometricData,
    updateAnthropometricData,
    addAnthropometricData,
    fetchAllAnthropometricData,
    setAnthropometricData,
    setShowForm,
}) => {
    const [formInfo,setFormInfo] = useState({ title:'Nuevos Datos Antropométricos', buttonTitle:'Aceptar' })
    const edit = anthropometricData !== null;

    useEffect(()=>{

        edit && setFormInfo({ title:'Editar Datos Antropométricos', 
                      buttonTitle:'Guardar' })
    }, [])
    useEffect(()=>{
        fetchAllAnthropometricData()
    }, [])

    const onSubmit = (values, { resetForm, setSubmitting }) => {
        if (!values.description) {
            delete values.description;
          }
        if (!values.madurityIndex) {
            delete values.madurityIndex;
        }
        else if(values.madurityIndex ==="ninguno"){
            values.madurityIndex = null;
        }
        if (!values.boneMuscleIndex) {
            delete values.boneMuscleIndex;
        }
        if (!values.subscapular){
            delete values.subscapular;
        }
        if (!values.suprailiac) {
            delete values.suprailiac;
        }
        if (!values.thighCalf){
            delete values.thighCalf;
        }
        if (!values.medialCalf){
            delete values.medialCalf;
        }
        if (!values.abdominal) {
            delete values.abdominal;
        }
        if(!values.triceps) {
            delete values.triceps;
        }
        if(!values.fatMassKg) {
            delete values.fatMassKg
        }
        if(!values.fatMassPercentage) {
            delete values.fatMassPercentage
        }
        if(!values.muscleMassKg) {
            delete values.muscleMassKg;
        }
        if(!values.muscleMassPercentage) {
            delete values.muscleMassPercentage;
        }
        if(!values.boneMassKg) {
            delete values.boneMassKg;
        }
        if (edit) {
            setSubmitting(true);
            return updateAnthropometricData(values, anthropometricData.id)
              .then(res => {
                setAnthropometricData(null);
                setSubmitting(false);
                resetForm(); // ver si es necesario
                fetchAllAnthropometricData();
              })
              .catch(() => setSubmitting(false)).finally(setShowForm(false));
        } else {
            setSubmitting(true);
            return addAnthropometricData(values).then(res => {
              setSubmitting(false);
              resetForm();
              fetchAllAnthropometricData();
            }).finally(setShowForm(false)); 
          }
    }
    return ( 
        <AnthropometricDataForm 
            edit={edit}
            anthropometricData={anthropometricData} 
            setAnthropometricData={setAnthropometricData}
            title={formInfo.title} 
            buttonTitle={formInfo.buttonTitle} 
            setShowForm={setShowForm}
            onSubmit={onSubmit}    
        />
    )

}

AnthropometricDataFormWrapper.propTypes = {
    setShowForm: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...anthropometricDataActions,
    },
    dispatch
  );

export default compose(
connect(
    null,
    mapDispatchToProps
)
)(AnthropometricDataFormWrapper);