import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const GENDER_ACTION_TYPES = {
  FETCH_GENDERS_SUCCESS: 'FETCH_GENDERS_SUCCESS',
  GENDER_ERROR: 'GENDER_ERROR',
};

export const fetchGenders = () => dispatch => {
  return fetchMiddleware('GET', '/gender/').then(
    json =>
      dispatch({
        type: GENDER_ACTION_TYPES.FETCH_GENDERS_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};
