import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {
  DEFAULT_DAYS,
  DEFAULT_DAYS_AL,
  DEFAULT_DAYS_MONOTONY,
  DEFAULT_OFFSET_CL,
} from '../../../../const/chart';

const FiltersSchema = Yup.object().shape({
  days: Yup.number()
    .min(DEFAULT_DAYS.MIN, 'Inválido')
    .max(DEFAULT_DAYS.MAX, 'Inválido')
    .required('Requerido'),
  offsetCl: Yup.number()
    .min(DEFAULT_OFFSET_CL.MIN, 'Inválido')
    .max(DEFAULT_OFFSET_CL.MAX, 'Inválido')
    .required('Requerido'),
  cantDaysAl: Yup.number()
    .min(DEFAULT_DAYS_AL.MIN, 'Inválido')
    .max(DEFAULT_DAYS_AL.MAX, 'Inválido')
    .required('Requerido'),
  cantDaysMonotony: Yup.number()
    .min(DEFAULT_DAYS_MONOTONY.MIN, 'Inválido')
    .max(DEFAULT_DAYS_MONOTONY.MAX, 'Inválido')
    .required('Requerido'),
});

const useStyles = makeStyles(theme => ({
  form: {
    height: '100%',
    width: '800px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
}));

const ChartFilters = ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        days: DEFAULT_DAYS.VALUE,
        offsetCl: DEFAULT_OFFSET_CL.VALUE,
        cantDaysAl: DEFAULT_DAYS_AL.VALUE,
        cantDaysMonotony: DEFAULT_DAYS_MONOTONY.VALUE,
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values).then(() => {
          setSubmitting(false);
        });
      }}
      validationSchema={FiltersSchema}
      validate={values => {
        const errors = {};
        const { days, offsetCl, cantDaysAl, cantDaysMonotony } = values;
        if (offsetCl > days) {
          errors.offsetCl = 'No puede ser mayor que días';
        }
        if (cantDaysAl > days) {
          errors.cantDaysAl = 'No puede ser mayor que días';
        }
        if (cantDaysMonotony > days) {
          errors.cantDaysMonotony = 'No puede ser mayor que días';
        }
        return errors;
      }}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Form className={classes.form} onSubmit={handleSubmit}>
          <Field name="days">
            {({
              field: { name, value, onChange, onBlur },
              form: { errors },
              meta,
            }) => (
              <TextField
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label="Días"
                variant="outlined"
                size="small"
                style={{ width: '70px' }}
                type="number"
                error={!!errors.days}
                helperText={errors.days || ' '}
              />
            )}
          </Field>

          <Field name="offsetCl">
            {({
              field: { name, value, onChange, onBlur },
              form: { errors },
              meta,
            }) => (
              <TextField
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label="Días ignorados carga crónica"
                variant="outlined"
                size="small"
                style={{ width: '235px' }}
                type="number"
                error={!!errors.offsetCl}
                helperText={errors.offsetCl || ' '}
              />
            )}
          </Field>

          <Field name="cantDaysAl">
            {({
              field: { name, value, onChange, onBlur },
              form: { errors },
              meta,
            }) => (
              <TextField
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label="Días carga aguda"
                variant="outlined"
                size="small"
                style={{ width: '150px' }}
                type="number"
                error={!!errors.cantDaysAl}
                helperText={errors.cantDaysAl || ' '}
              />
            )}
          </Field>

          <Field name="cantDaysMonotony">
            {({
              field: { name, value, onChange, onBlur },
              form: { errors },
              meta,
            }) => (
              <TextField
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                label="Días monotonía"
                variant="outlined"
                size="small"
                style={{ width: '140px' }}
                type="number"
                error={!!errors.cantDaysMonotony}
                helperText={errors.cantDaysMonotony || ' '}
              />
            )}
          </Field>
          <Button
            style={{ marginTop: '5px' }}
            color={'primary'}
            variant={'contained'}
            size={'small'}
            type={'submit'}
            disabled={!isValid || isSubmitting}
          >
            Aplicar
          </Button>
        </Form>
      )}
    </Formik>
  );
};

ChartFilters.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ChartFilters;
