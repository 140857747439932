import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const TEAM_ACTION_TYPES = {
  FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
  FETCH_MY_TEAMS_SUCCESS: 'FETCH_MY_TEAMS_SUCCESS',
  FETCH_TEAM_SUCCESS: 'FETCH_TEAM_SUCCESS',
  FETCH_TEAM_PLAYERS_SUCCESS: 'FETCH_TEAM_PLAYERS_SUCCESS',
  FETCH_TEAM_MEMBERS_SUCCESS: 'FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_TEAM_ROLES_SUCCESS: 'FETCH_TEAM_ROLES_SUCCESS',
  FETCH_TEAM_POSITION_SUCCESS: 'FETCH_TEAM_POSITION_SUCCESS',
  TEAM_ERROR: 'TEAM_ERROR',
};

export const fetchTeams = () => dispatch => {
  return fetchMiddleware('GET', '/team/').then(
    json =>
      dispatch({ type: TEAM_ACTION_TYPES.FETCH_TEAMS_SUCCESS, payload: json }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const fetchMyTeams = () => dispatch => {
  return fetchMiddleware('GET', '/team/my_teams/').then(
    json =>
      dispatch({
        type: TEAM_ACTION_TYPES.FETCH_MY_TEAMS_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const fetchTeam = id => dispatch => {
  return fetchMiddleware('GET', `/team/${id}/`).then(
    json =>
      dispatch({ type: TEAM_ACTION_TYPES.FETCH_TEAM_SUCCESS, payload: json }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const fetchTeamPlayers = id => dispatch => {
  return fetchMiddleware('GET', `/team/${id}/players/`).then(
    json =>
      dispatch({
        type: TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const fetchTeamMembers = id => dispatch => {
  return fetchMiddleware('GET', `/team/${id}/members/`).then(
    json =>
      dispatch({
        type: TEAM_ACTION_TYPES.FETCH_TEAM_MEMBERS_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const resetTeam = () => dispatch => {
  return dispatch({
    type: TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS,
    payload: {},
  });
};

export const resetTeamPlayers = () => dispatch => {
  return dispatch({
    type: TEAM_ACTION_TYPES.FETCH_TEAM_PLAYERS_SUCCESS,
    payload: [],
  });
};

export const resetTeamMembers = () => dispatch => {
  return dispatch({
    type: TEAM_ACTION_TYPES.FETCH_TEAM_MEMBERS_SUCCESS,
    payload: [],
  });
};

export const addTeam = team => dispatch => {
  return fetchMiddleware('POST', '/team/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(team),
  }).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Equipo agregado correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

export const updateTeam = team => dispatch => {
  return fetchMiddleware('PUT', `/team/${team.id}/`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(team),
  }).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Equipo actualizado correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

export const deleteTeam = id => dispatch => {
  return fetchMiddleware('DELETE', `/team/${id}`).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Equipo eliminado correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

// TODO move role store

export const fetchTeamRoles = () => dispatch => {
  return fetchMiddleware('GET', `/role_team/`).then(
    json =>
      dispatch({
        type: TEAM_ACTION_TYPES.FETCH_TEAM_ROLES_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};

// TODO move member store

export const addMember = member => dispatch => {
  return fetchMiddleware('POST', '/member/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(member),
  }).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Miembro agregado correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

export const updateMember = member => dispatch => {
  return fetchMiddleware('PUT', `/member/${member.id}/`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(member),
  }).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Miembro actualizado correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

export const deleteMember = id => dispatch => {
  return fetchMiddleware('DELETE', `/member/${id}/`).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Miembro eliminado correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

// TODO move to position store

export const fetchPositions = () => dispatch => {
  return fetchMiddleware('GET', `/position/`).then(
    json =>
      dispatch({
        type: TEAM_ACTION_TYPES.FETCH_TEAM_POSITION_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};
