import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {
  isSignedInSelector,
  personSelector,
  userSelector,
} from '../auth/AuthSelectors';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as authActions from '../auth/AuthActions';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { history } from '../../store/Store';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  avatar: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
  logoutButton: {
    color: theme.palette.primary.contrastText,
  },
  appBar: {
    width: '100%',
    zIndex: 100000,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = ({ onDrawerToggled, logout, isSignedIn, authRequested }) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={onDrawerToggled}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ cursor: 'pointer' }}
            variant="h4"
            onClick={() => history.push('/')}
          >
            Machi
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = state => ({
  isSignedIn: isSignedInSelector(state),
  user: userSelector(state),
  person: personSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...authActions }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Header);
