import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import {
    Card,
    CardContent, 
    Typography, 
    Grid,
    Button,
    Select,
    FormControl,
    InputLabel,
    TextField,
    Chip,
    InputAdornment,
    makeStyles,
    MenuItem,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { green, orange, red } from '@material-ui/core/colors';
// fetch
import { 
    fetchAllStage, 
    fetchAllPurpose, 
    fetchAllMadurityIndex, 
} from './AnthropometricDataActions';

import * as teamActions from '../team/TeamActions';
import { myTeamsSelector, teamPlayersSelector } from '../team/TeamSelectors';


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    sectionTitle: {
        marginBottom: theme.spacing(2),
    },
    internalSectionTitle: {
        marginTop: theme.spacing(4)
    },
    textField: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
        width: 300,
    },
    textFieldWithoutBottom: {
        marginRight: theme.spacing(1),
        width: 300,
    },
    textFieldSmall: {
        marginTop: theme.spacing(3),
        width: 180,
    },
    textFieldFullWidth: {
        marginTop: theme.spacing(3),
        width: '100%',
        maxWidth: "850px",
        overflowX: "hidden"
    },
    gridIndices: {
        marginBottom: theme.spacing(2)
    },
    iconChip: {
        color:"white"
    },
    optimoChip: {
        marginTop: theme.spacing(3),
        backgroundColor: green[400], 
        color:"white"
    },
    alertaChip: {
        marginTop: theme.spacing(3),
        backgroundColor: orange[400], 
        color:"white"
    },
    excesivoChip: {
        marginTop: theme.spacing(3),
        backgroundColor: red[400], 
        color:"white"
    },
    disabledChip: {
        marginTop: theme.spacing(3),
        color:"white"
    },
    buttonTitleSection:{
        color:"black",
        textTransform: 'capitalize',
    },
    containerAcordeon:{
        marginBottom: theme.spacing(4),
    },
    button: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(1),
      },
    buttonMasa: {
        marginTop: "27px",
        marginLeft: theme.spacing(1),
        height: "27px"
    },
  }));


export const AnthropometricDataForm = ({
    setAnthropometricData,
    anthropometricData,
    title,
    edit,
    buttonTitle,
    setShowForm,
    onSubmit,
    fetchMyTeams,
    fetchTeamPlayers,
    myTeams,
    players,
}) => {
    const classes = useStyles();
    const [showSumatoriaPliegues, setShowSumatoriaPliegues] = useState(false);
    const [showAntropometriaCompleta, setShowAntropometriaCompleta] = useState(false);
    const today = new Date().toISOString().replace(/\T.*/, '');

    // list
    const [stage, setStage] = useState([])
    const [purpose, setPurpose] = useState([])
    const [madurityIndex, setMadurityIndex] = useState([])
    const [masaCalculada, setMasaCalculada] = useState("")

    const [teamOptions, setTeamOptions] = useState([]);
    const [playerOptions, setPlayerOptions] = useState([]);
  
    // sumatoria de 6 pliegues
    const calcularMasa = (subscapular, suprailiac, medialCalf, abdominal, thighCalf, triceps ) => {
        const resultado = Number(subscapular) + Number(suprailiac) + Number(medialCalf) + Number(abdominal) + Number(thighCalf) + Number(triceps);
        if ( resultado < 50){
            setMasaCalculada("optimo")
        }
        else if (50 <= resultado && resultado < 60 ){
            setMasaCalculada("alerta")
        }
        else {
            setMasaCalculada("excesivo")
        }
    }

    useEffect(() => {
        if(!edit){
            fetchMyTeams();  
        }
    }, [])

    useEffect(() =>{
        if (myTeams){
            const options = [];
            const filteredOptions = [];
            if (myTeams.physicalStaff) {
                myTeams.physicalStaff.map((team) => {
                  options.push(team)
                });
              }
              if (myTeams.medicalStaff) {
                myTeams.medicalStaff.map((team) => {
                  options.push(team)
                });
              }
            const filtered = options.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
            filtered.map(team => {
                filteredOptions.push(
                    <MenuItem key={team.id} value={team.id}>
                      {team.name}
                    </MenuItem>
                )
            })
            setTeamOptions(filteredOptions); 
        };
    }, [myTeams])

    useEffect(() => {
        if(players){
            const playersOpt = [];
            players.map((player) => {
                playersOpt.push(
                    <MenuItem key={player.person.id} value={player.person.id}>
                    {`${player.person.firstName} ${player.person.lastName}`}
                    </MenuItem>
                );
            });
            setPlayerOptions(playersOpt);
        }  
    }, [players])

    const fetchPlayersOfTeam = (team) => {
        fetchTeamPlayers(team);
    };
    
    useEffect(() => {
        fetchAllStage().then((res) => {
            const stageOptions = [];
            res.map((stg) => {
                stageOptions.push(
                    <MenuItem key={stg.id} value={stg.id}>
                    {stg.name}
                    </MenuItem>
                );
                });
            setStage(stageOptions)});
    },[])

    useEffect(() => {
        fetchAllPurpose().then((res) => {
            const purposeOptions = [];
            res.map((prps) => {
                purposeOptions.push(
                    <MenuItem key={prps.id} value={prps.id}>
                    {prps.description}
                    </MenuItem>
                );
            });
        setPurpose(purposeOptions)});  
    },[])

    useEffect(() => {
        fetchAllMadurityIndex().then((res) => {
            const madurityIndexOptions = [];
            res.map((mdIx) => {
                madurityIndexOptions.push(
                    <MenuItem key={mdIx.id} value={mdIx.id}>
                    {mdIx.description}
                    </MenuItem>
                );
            });
        setMadurityIndex(madurityIndexOptions)});  
    },[])

    // validations
    const validate = (values) => {
        const errors = {};

        error = fieldValidation(values.person);
        if (error) {errors.person = error;}

        error = fieldValidation(values.stage);
        if (error) {errors.stage = error;}

        error = fieldValidation(values.purpose);
        if (error) {errors.purpose = error;}

        error = injuryDateValidation(values.date);
        if (error) {errors.date = error;}

        let error = fieldValidation(values.weight);
        if (error) { errors.weight = error; }
        error = numberFieldValidation(values.weight);
        if (error) { errors.weight = error; }

        error = fieldValidation(values.height);
        if (error) { errors.height = error; }
        error = numberFieldValidation(values.height)
        if (error) { errors.height = error; }

        error = fieldValidation(values.bodyMassIndex);
        if (error) { errors.bodyMassIndex = error;}
        error = numberFieldValidation(values.bodyMassIndex);
        if (error) { errors.bodyMassIndex = error;}

        error = numberFieldValidation(values.boneMuscleIndex);
        if (error) { errors.boneMuscleIndex = error;}

        error = numberFieldValidation(values.triceps);
        if (error) { errors.triceps = error; setShowSumatoriaPliegues(true)}
        
        error = numberFieldValidation(values.subscapular);
        if (error) { errors.subscapular = error; setShowSumatoriaPliegues(true)}

        error = numberFieldValidation(values.suprailiac);
        if (error) { errors.suprailiac = error; setShowSumatoriaPliegues(true)}

        error = numberFieldValidation(values.abdominal);
        if (error) { errors.abdominal = error; setShowSumatoriaPliegues(true)}

        error = numberFieldValidation(values.thighCalf);
        if (error) { errors.thighCalf = error; setShowSumatoriaPliegues(true)}

        error = numberFieldValidation(values.medialCalf);
        if (error) { errors.medialCalf = error; setShowSumatoriaPliegues(true)}

        error = numberFieldValidation(values.fatMassKg);
        if (error) { errors.fatMassKg = error; setShowAntropometriaCompleta(true)}

        error = numberFieldValidation(values.muscleMassKg);
        if (error) { errors.muscleMassKg = error; setShowAntropometriaCompleta(true)}

        error = numberFieldValidation(values.boneMassKg);
        if (error) { errors.boneMassKg = error; setShowAntropometriaCompleta(true)}

        error = numberFieldValidation(values.fatMassPercentage);
        if (error) { errors.fatMassPercentage = error; setShowAntropometriaCompleta(true)}

        error = numberFieldValidation(values.muscleMassPercentage);
        if (error) { errors.muscleMassPercentage = error; setShowAntropometriaCompleta(true)}

        return errors;
      };
    
    const fieldValidation = (value) => {
        let error;
          if (!value) {
            error = 'Este campo es obligatorio';
          }
        return error;
    };

    const numberFieldValidation = (value) => {
        let error;
        if(value!==null){
            if(value.replace(/\d/g, '').replace(/\./, '').length !== 0){
                error = 'Debe ser un número';
            }
        }
        return error;
    };

    const injuryDateValidation = (value) => {
        let error;
        if (!value) {
          error = 'Este campo es obligatorio';
        } else if (value > today) {
            error = 'Fecha inválida';
        }
        return error;
    };

    // formik
    const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
        person: edit ? anthropometricData.person.id : '', // *
        stage: edit ? anthropometricData.stage.id: '', // *
        date: edit ? anthropometricData.date : today, // *
        weight: edit ? anthropometricData.weight: '', // *
        height: edit ? anthropometricData.height: '', // *
        bodyMassIndex: edit ? anthropometricData.bodyMassIndex: '', // *
        description: edit ? anthropometricData.description : '',
        madurityIndex: (edit && anthropometricData.madurityIndex) ? anthropometricData.madurityIndex.id: '',
        boneMuscleIndex: edit ? anthropometricData.boneMuscleIndex: '',
        subscapular: edit ? anthropometricData.subscapular: '',
        suprailiac: edit ? anthropometricData.suprailiac: '',
        thighCalf: edit ? anthropometricData.thighCalf: '',
        medialCalf: edit ? anthropometricData.medialCalf: '',
        abdominal: edit ? anthropometricData.abdominal: '',
        triceps: edit ? anthropometricData.triceps: '',
        fatMassKg: edit ? anthropometricData.fatMassKg: '',
        muscleMassKg: edit ? anthropometricData.muscleMassKg: '',
        boneMassKg: edit ? anthropometricData.boneMassKg: '',
        fatMassPercentage: edit ? anthropometricData.fatMassPercentage: '',
        muscleMassPercentage: edit ? anthropometricData.muscleMassPercentage: '',
        purpose: edit ? anthropometricData.purpose.id: '' // *
    },
    onSubmit: onSubmit,
    validate,
    });

    

    return(
    <Card className={classes.root} id="anthropometric-data-form">
    <CardContent>
        <Typography
            variant="subtitle2"
            color="textPrimary"
            className={classes.sectionTitle} >
                {title}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
        <Grid container>
            <Typography
                    variant="subtitle2"
                    color="textPrimary"
                    className={classes.sectionTitle} >
                    Datos Generales
            </Typography>
            <Grid container>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                >
                {!edit &&
                    <Grid item xs={6}>
                        <FormControl>
                        <InputLabel>
                            Equipo *
                        </InputLabel>
                        <Select
                            disabled={edit}
                            label="Equipo"
                            style={{ width: '300px' }}
                            onChange={(event) => {fetchPlayersOfTeam(event.target.value);
                            }}
                        >
                            {teamOptions}
                        </Select>
                        </FormControl>
                    </Grid>
                }
                    <Grid item xs={edit ? 12 : 6}>
                        <FormControl>
                        <InputLabel>
                            Jugador *
                        </InputLabel>
                        <Select
                            disabled={edit}
                            {...formik.getFieldProps('person')}
                            label="Jugador"
                            style={{ width: '300px' }}
                            onChange={(event) => {
                            formik.setFieldValue('person', event.target.value);
                            }}
                        >
                            {edit ? <MenuItem key={anthropometricData.person.id} value={anthropometricData.person.id}>
                                        {anthropometricData.person.firstName}
                                    </MenuItem> 
                                  : playerOptions}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                        <InputLabel>
                            Etapa del año *
                        </InputLabel>
                        <Select
                            disabled={edit}
                            {...formik.getFieldProps('stage')}
                            label="Etapa del año"
                            style={{ width: '300px' }}
                            onChange={(event) => {
                            formik.setFieldValue('stage', event.target.value);
                            }}
                        >
                            {stage}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl>
                        <TextField
                        disabled={edit}
                        {...formik.getFieldProps('date')}
                        label="Fecha de evaluacion *"
                        type="date"
                        className={classes.textFieldWithoutBottom}
                        error={ formik.touched.date && !!formik.errors.date }
                        helperText={ formik.touched.date ? formik.errors.date : ''}
                        InputLabelProps={{
                            shrink: true,  
                        }}
                        {...formik.getFieldProps('date')}
                        />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item sm={10}>
                        <TextField
                        label="Descripción"
                        type="text"
                        placeholder="Ej: ??? "
                        className={classes.textFieldFullWidth}
                        {...formik.getFieldProps('description')}
                        />
                </Grid>
        </Grid>
        <Typography
            variant="subtitle2"
            color="textPrimary"
            className={classes.internalSectionTitle} >
                Datos Antropométricos
        </Typography>
        <Grid
            container
            spacing={1}
            direction="row"
            justify="space-around"
            alignItems="center"
            style={{"marginBottom":"20px"}}
        >
            <Grid item xs={12} sm={6}>
                <FormControl>
                <TextField
                    label="Peso corporal *"
                    className={classes.textField}
                    error={ formik.touched.weight && !!formik.errors.weight }
                    helperText={ formik.touched.weight ? formik.errors.weight : ''}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                    }}
                    {...formik.getFieldProps('weight')}
                />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl>
                <TextField
                    label="Talla *"
                    className={classes.textField}
                    error={ formik.touched.height && !!formik.errors.height }
                    helperText={ formik.touched.height ? formik.errors.height : ''}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Cm</InputAdornment>,
                    }}
                    {...formik.getFieldProps('height')}
                />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridIndices}>
                <FormControl>
                <TextField
                    label="Índice de músculo óseo (IMO)"
                    type="text"
                    className={classes.textField}
                    error={ formik.touched.boneMuscleIndex && !!formik.errors.boneMuscleIndex }
                    helperText={ formik.touched.boneMuscleIndex ? formik.errors.boneMuscleIndex : ''}
                    {...formik.getFieldProps('boneMuscleIndex')}
                />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridIndices}>
                <FormControl>
                <TextField
                    label="Índice de masa corporal (IMC) *"
                    type="text"
                    className={classes.textField}
                    error={ formik.touched.bodyMassIndex && !!formik.errors.bodyMassIndex }
                    helperText={ formik.touched.bodyMassIndex ? formik.errors.bodyMassIndex : ''}
                    {...formik.getFieldProps('bodyMassIndex')}
                />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl>
                    <InputLabel>
                        Indice Madurativo
                    </InputLabel>
                    <Select
                        {...formik.getFieldProps('madurityIndex')}
                        label="Indice Madurativo"
                        style={{ width: '300px' }}
                        onChange={(event) => {
                        formik.setFieldValue('madurityIndex', event.target.value);
                        }}
                    >
                        <MenuItem value={"ninguno"}>Ninguno</MenuItem>
                        {madurityIndex}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                    size="small"
                    className={classes.buttonTitleSection}
                    onClick={() => setShowSumatoriaPliegues(!showSumatoriaPliegues)}
                    >
                    {showSumatoriaPliegues ? <ExpandLessIcon/>:<ExpandMoreIcon/> 
                    } Sumatoria de 6 pliegues
                    </Button>
                </Grid>
                {showSumatoriaPliegues &&                  
                <Grid container
                    xs={11} className={classes.containerAcordeon}
                >
                    <Grid item xs={11} sm={4}>
                            <FormControl>
                            <TextField
                                label="Tricipital"
                                placeholder="0.0"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.textFieldSmall}
                                error={ formik.touched.triceps && !!formik.errors.triceps }
                                helperText={ formik.touched.triceps ? formik.errors.triceps : ''}
                                {...formik.getFieldProps('triceps')}
                            />
                            </FormControl>
                    </Grid>
                    <Grid item xs={11} sm={4}>
                            <FormControl>
                            <TextField
                                label="Subescapular"
                                placeholder="0.0"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.textFieldSmall}
                                error={ formik.touched.subscapular && !!formik.errors.subscapular }
                                helperText={ formik.touched.subscapular ? formik.errors.subscapular : ''}
                                {...formik.getFieldProps('subscapular')}
                            />
                            </FormControl>
                    </Grid>
                    <Grid item xs={11} sm={3}>
                            <FormControl>
                            <TextField
                                label="Supraespinal"
                                className={classes.textFieldSmall}
                                error={ formik.touched.suprailiac && !!formik.errors.suprailiac }
                                helperText={ formik.touched.suprailiac ? formik.errors.suprailiac : ''}
                                {...formik.getFieldProps('suprailiac')}
                            />
                            </FormControl>
                    </Grid>
                    <Grid item xs={11} sm={4}>
                            <FormControl>
                            <TextField
                                label="Muslo medio"
                                className={classes.textFieldSmall}
                                error={ formik.touched.medialCalf && !!formik.errors.medialCalf }
                                helperText={ formik.touched.medialCalf ? formik.errors.medialCalf : ''}
                                {...formik.getFieldProps('medialCalf')}
                            />
                            </FormControl>
                    </Grid>
                    <Grid item xs={11} sm={4}>
                            <FormControl>
                            <TextField
                                label="Abdominal"
                                className={classes.textFieldSmall}
                                error={ formik.touched.abdominal && !!formik.errors.abdominal }
                                helperText={ formik.touched.abdominal ? formik.errors.abdominal : ''}
                                {...formik.getFieldProps('abdominal')}
                            />
                            </FormControl>
                    </Grid>
                    <Grid item xs={11} sm={3}>
                            <FormControl>
                            <TextField
                                label="Pantorilla"
                                className={classes.textFieldSmall}
                                error={ formik.touched.thighCalf && !!formik.errors.thighCalf }
                                helperText={ formik.touched.thighCalf ? formik.errors.thighCalf : ''}
                                {...formik.getFieldProps('thighCalf')}
                            />
                            </FormControl>
                    </Grid>
                    <Grid container> 
                            <Chip label="Optimo" className={ (masaCalculada === "optimo") ? classes.optimoChip : classes.disabledChip } icon={<DoneIcon className={classes.iconChip} />} ></Chip>
                            <Chip label="Alerta" className={ (masaCalculada === "alerta") ? classes.alertaChip : classes.disabledChip } icon={<ErrorOutlineIcon className={classes.iconChip} />}></Chip>
                            <Chip label="Excesiva masa adiposa" className={masaCalculada === "excesivo" ? classes.excesivoChip : classes.disabledChip} icon={<ErrorOutlineIcon className={classes.iconChip} />}></Chip>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                className={classes.buttonMasa}
                                onClick={() => {
                                    calcularMasa(formik.getFieldProps('subscapular').value, formik.getFieldProps('suprailiac').value,formik.getFieldProps('medialCalf').value,formik.getFieldProps('abdominal').value,formik.getFieldProps('thighCalf').value,formik.getFieldProps('triceps').value)
                                }}
                            >
                                Calcular Masa Adiposa 
                            </Button>
                    </Grid>
                </Grid>
                }              
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Button
                    size="small"
                    className={classes.buttonTitleSection}
                    onClick={() => setShowAntropometriaCompleta(!showAntropometriaCompleta)}
                    >
                    {showAntropometriaCompleta ? <ExpandLessIcon/>:<ExpandMoreIcon/> 
                    } Antropometria Completa
                    </Button>
                </Grid>
            {showAntropometriaCompleta &&                  
                <Grid container
                    xs={11} className={classes.containerAcordeon}
                >
                        <Grid item xs={11} sm={4}>
                                <FormControl>
                                <TextField
                                    label="Masa adiposa"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                    }}
                                    className={classes.textFieldSmall}
                                    error={ formik.touched.fatMassKg && !!formik.errors.fatMassKg }
                                    helperText={ formik.touched.fatMassKg ? formik.errors.fatMassKg : ''}
                                    {...formik.getFieldProps('fatMassKg')}
                                />
                                </FormControl>
                        </Grid>
                        <Grid item xs={11} sm={4}>
                                <FormControl>
                                <TextField
                                    label="Masa múscular"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                    }}
                                    className={classes.textFieldSmall}
                                    error={ formik.touched.muscleMassKg && !!formik.errors.muscleMassKg }
                                    helperText={ formik.touched.muscleMassKg ? formik.errors.muscleMassKg : ''}
                                    {...formik.getFieldProps('muscleMassKg')}
                                />
                                </FormControl>
                        </Grid>
                        <Grid item xs={11} sm={3}>
                                <FormControl>
                                <TextField
                                    label="Masa ósea"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                    }}
                                    className={classes.textFieldSmall}
                                    error={ formik.touched.boneMassKg && !!formik.errors.boneMassKg }
                                    helperText={ formik.touched.boneMassKg ? formik.errors.boneMassKg : ''}
                                    {...formik.getFieldProps('boneMassKg')}
                                />
                                </FormControl>
                        </Grid>
                        <Grid item xs={11} sm={4}>
                                <FormControl>
                                <TextField
                                    label="Masa adiposa"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    className={classes.textFieldSmall}
                                    error={ formik.touched.fatMassPercentage && !!formik.errors.fatMassPercentage }
                                    helperText={ formik.touched.fatMassPercentage ? formik.errors.fatMassPercentage : ''}
                                    {...formik.getFieldProps('fatMassPercentage')}
                                />
                                </FormControl>
                        </Grid>
                        <Grid item xs={11} sm={8}>
                                <FormControl>
                                <TextField
                                    label="Masa múscular"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    className={classes.textFieldSmall}
                                    error={ formik.touched.muscleMassPercentage && !!formik.errors.muscleMassPercentage }
                                    helperText={ formik.touched.muscleMassPercentage ? formik.errors.muscleMassPercentage : ''}
                                    {...formik.getFieldProps('muscleMassPercentage')}
                                />
                                </FormControl>
                        </Grid>
                </Grid>
                }              
                <Grid item xs={12} style={{ "marginTop" : "20px"}}>
                            <FormControl>
                            <InputLabel>
                                Objetivo *
                            </InputLabel>
                            <Select
                                {...formik.getFieldProps('purpose')}
                                label="Objetivo *"
                                style={{ width: '300px' }}
                                onChange={(event) => {
                                formik.setFieldValue('purpose', event.target.value);
                                }}
                            >
                                {purpose}
                            </Select>
                            </FormControl>
                        </Grid>
            </Grid>
            <Grid
                xs={9}
                container
                direction="row"
                justify="center"
                alignItems="center"
            >   
                <Button
                    type="reset"
                    size="small"
                    color="primary"
                    disabled={formik.isSubmitting}
                    className={classes.button}
                    onClick={() => {
                        setAnthropometricData(null);
                        setShowForm(false);
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    type="submit"
                    disabled={formik.isSubmitting}
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.button}
                >
                    {buttonTitle}
                </Button>
            </Grid>
        </Grid>
        </form>
    </CardContent>
    </Card>
    )
}

AnthropometricDataForm.propTypes = {
    anthropometricData: PropTypes.object,
    setAnthropometricData: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    edit: PropTypes.bool.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    setShowForm: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };
  
const mapStateToProps = (state) => ({
myTeams: myTeamsSelector(state),
players: teamPlayersSelector(state),
});

const mapDispatchToProps = (dispatch) =>
bindActionCreators(
    {
    ...teamActions,
    },
    dispatch
);

export default compose(connect(mapStateToProps, mapDispatchToProps))(AnthropometricDataForm);