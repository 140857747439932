import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const INJURY_ACTION_TYPES = {
  FETCH_INJURIES_SUCCESS: 'FETCH_INJURIES_SUCCESS',
  FETCH_TEAM_INJURIES_SUCCESS: 'FETCH_TEAM_INJURIES_SUCCESS',
  FETCH_INJURY_OBSERVATIONS_SUCCESS: 'FETCH_INJURY_OBSERVATIONS_SUCCESS',
  FETCH_INJURY_OBSERVATIONS_ERROR: 'FETCH_INJURY_OBSERVATIONS_ERROR',
  ADD_INJURY_OBSERVATION_SUCCESS: 'ADD_INJURY_OBSERVATION_SUCCESS',
  ADD_INJURY_OBSERVATION_ERROR: 'ADD_INJURY_OBSERVATION_ERROR',
  INJURY_ERROR: 'INJURY_ERROR',
};

export const addInjury = (injury) => (dispatch) => {
  return fetchMiddleware('POST', '/injury/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(injury),
  }).then(
    () => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Lesion agregada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
    },
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchInjuries = () => (dispatch) => {
  return fetchMiddleware('GET', '/injury/').then(
    (json) =>
      dispatch({
        type: INJURY_ACTION_TYPES.FETCH_INJURIES_SUCCESS,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchTeamInjuries = (id) => (dispatch) => {
  return fetchMiddleware(
    'GET',
    `/injury/players/?idTeam=${id}&injured=true`
  ).then(
    (json) =>
      dispatch({
        type: INJURY_ACTION_TYPES.FETCH_TEAM_INJURIES_SUCCESS,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const updateInjury = (injury, id) => (dispatch) => {
  return fetchMiddleware('PUT', `/injury/${id}/`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(injury),
  }).then(
    (json) => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Lesión actualizada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const deleteInjury = (id) => (dispatch) => {
  return fetchMiddleware('DELETE', `/injury/${id}/`).then(
    (json) => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Lesion eliminada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchInjuryObservations = (id) => async (dispatch) => {
  try {
    const response = await fetchMiddleware(
      'GET',
      `/observation/?idInjury=${id}`
    );
    response
      ? dispatch({
          type: INJURY_ACTION_TYPES.FETCH_INJURY_OBSERVATIONS_SUCCESS,
          payload: response,
        })
      : dispatch({ type: INJURY_ACTION_TYPES.FETCH_INJURY_OBSERVATIONS_ERROR });
    return response;
  } catch (error) {
    actionErrorHandler(error, dispatch);
  }
};

/* export const addInjuryObservation = (observation) => async (dispatch) => {
  console.log('estoy en la action');
  try {
    const response = await fetchMiddleware('POST', '/observation/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(observation),
    });
    response
      ? dispatch({
          ttype: INJURY_ACTION_TYPES.FETCH_ADD_INJURY_OBSERVATION_SUCCESS,
        })
      : dispatch({ type: INJURY_ACTION_TYPES.ADD_INJURY_OBSERVATION_ERROR });
    return response;
  } catch (error) {
    actionErrorHandler(error, dispatch);
  }
}; */

export const addInjuryObservation = (observation) => (dispatch) => {
  return fetchMiddleware('POST', '/observation/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(observation),
  }).then(
    (json) => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Observacion agregada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    (error) => actionErrorHandler(error, dispatch)
  );
};
