export const teamPlayerSelector = (state) => {
  return state.profile.teamPlayer;
};
export const RPEPlayerSelector = (state) => {
  return state.profile.RPEPlayer;
};
export const skillCategorySelector = (state) => {
  return state.profile.skillCategory;
};
export const technicalAttributesPlayerSelector = (state) => {
  return state.profile.technicalAttributes;
};
export const wellnessPlayerSelector = (state) => {
  return state.profile.wellness;
};
export const anthropometricPlayerSelector = (state) => {
  return state.profile.anthropometric;
};
