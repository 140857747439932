import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
}));

const PrivacyPolicy = ({}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography align="center" variant="h4" gutterBottom>
        Políticas de privacidad
      </Typography>

      <Typography variant="h5" gutterBottom>
        Introducción
      </Typography>
      <Typography variant="subtitle1" paragraph>
        A través del mismo, podrán ingresar la información relativa a los test
        que su club así disponga. En nuestra aplicación, respetamos su
        información personal y en vista de cumplir con las políticas de
        seguridad respectivas concernientes a toda aplicación, que deberían ser
        obligatorias, informo a ustedes lo siguiente.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Privacidad de los datos personales
      </Typography>
      <Typography variant="subtitle1" paragraph>
        Sus datos personales le corresponden solo a usted y esta aplicación es
        responsable de no revelar ninguna clase de información que le
        pertenezca, salvo su expresa autorización o fuerzas de naturaleza mayor
        de tipo legal que lo involucren, como hackeos o suplantaciones.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Seguridad de su información personal
      </Typography>
      <Typography variant="subtitle1" paragraph>
        Esta aplicación se hace responsable de velar por su seguridad, por la
        privacidad de su información y por el respeto a sus datos, de acuerdo
        con las limitaciones que la actual Internet nos provee, siendo
        conscientes que no estamos excluidos de sufrir algún ataque por parte de
        crackers o usuarios malintencionados que ejerzan la delincuencia
        informática.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Obtención de su información
      </Typography>
      <Typography variant="subtitle1" paragraph>
        Todos sus datos personales consignados en esta aplicación son
        suministrados por usted mismo, haciendo uso entero de su libertad. La
        información aquí almacenada solo comprende datos básicos ingresados
        mediante comandos determinados.
      </Typography>

      <Typography variant="h5" gutterBottom>
        Uso de la información
      </Typography>
      <Typography variant="subtitle1" paragraph>
        Al proporcionarnos sus datos personales, estando de acuerdo con la
        Política de Privacidad aquí consignada, nos autoriza para el siguiente
        uso de su información:
        <br />
        a) para el fin mismo por lo cual se ha suministrado.
      </Typography>
    </div>
  );
};

export default PrivacyPolicy;
