import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { injuriesSelector } from './InjurySelector';
import * as injuryActions from './InjuryActions';

import InjuryForm from './InjuryForm';

export const InjuryFormWrapper = ({
  addInjury,
  updateInjury,
  fetchInjuries,
  injury,
  setInjury,
  setMostrarForm,
}) => {
  // const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState('Nueva lesión');
  const [buttonTitle, setButtonTitle] = useState('Aceptar');
  const edit = injury !== null;

  useEffect(() => {
    if (injury !== null) {
      setTitle('Editar lesión');
      setButtonTitle('Guardar');
    } else {
      setTitle('Nueva lesión');
      setButtonTitle('Aceptar');
    }
  }, [injury]);

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    delete values.team;
    if (!values.returnToTrainingDate) {
      delete values.returnToTrainingDate;
    }
    if (!values.returnToPlayDate) {
      delete values.returnToPlayDate;
    }
    if (!values.returnToTeam) {
      delete values.returnToTeam;
    }
    if (edit) {
      setSubmitting(true);
      return updateInjury(values, injury.id)
        .then(res => {
          setInjury(null);
          setSubmitting(false);
          fetchInjuries();
        })
        .catch(() => setSubmitting(false)).finally(setMostrarForm(false));
    } else {
      setSubmitting(true);
      return addInjury(values).then(res => {
        setSubmitting(false);
        resetForm();
        fetchInjuries();
      }).finally(setMostrarForm(false));
    }
  };

  return (
    <InjuryForm
      edit={edit}
      onSubmit={onSubmit}
      title={title}
      buttonTitle={buttonTitle}
      injury={injury}
      setInjury={setInjury}
      setMostrarForm={setMostrarForm}
    />
  );
};

const mapStateToProps = state => ({
  injuries: injuriesSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...injuryActions,
    },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(InjuryFormWrapper);
