import { errorHandler } from './errorHandler';
import { API_BASE_URL, BASE_URL, GATEKEEPER_BASE_URL } from '../const/config';

export const fetchMiddleware = (method = 'GET', url, params = {}) => {
  params.headers
    ? (params.headers.Authorization = `Token ${localStorage.getItem('token')}`)
    : (params.headers = {
      Authorization: `Token ${localStorage.getItem('token')}`,
    });

  return fetch(API_BASE_URL + url, { ...params, method }).then(response => {
    if (response.ok) {
      return response.status !== 204
        ? Promise.resolve(response.json())
        : Promise.resolve();
    } else {
      return errorHandler(response).then(msg => {
        return Promise.reject(msg);
      });
    }
  });
};

export const publicFetchMiddleware = (
  method = 'GET',
  url,
  params = {},
  shouldAuthorize = false,
) => {
  if (shouldAuthorize) {
    params.headers
      ? (params.headers.Authorization = `Token ${localStorage.getItem(
      'token',
      )}`)
      : (params.headers = {
        Authorization: `Token ${localStorage.getItem('token')}`,
      });
  }
  return fetch(BASE_URL + url, { ...params, method }).then(response => {
    if (response.ok) {
      return response.status !== 204
        ? Promise.resolve(response.json())
        : Promise.resolve();
    } else {
      return errorHandler(response).then(msg => {
        return Promise.reject(msg);
      });
    }
  });
};

export const gatekeeperFetchMiddleware = (
  method = 'GET',
  url,
  params = {},
) => fetch(GATEKEEPER_BASE_URL + url, { ...params, method }).then(response => {
  if (response.ok) {
    return response.status !== 204
      ? Promise.resolve(response.json())
      : Promise.resolve();
  } else {
    return errorHandler(response).then(msg => {
      return Promise.reject(msg);
    });
  }
});