import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import home from '../modules/home/HomeReducer';
import team from '../modules/team/TeamReducer';
import category from '../modules/category/CategoryReducer';
import nationality from '../modules/nationality/NationalityReducer';
import person from '../modules/person/PersonReducer';
import auth from '../modules/auth/AuthReducer';
import gender from '../modules/gender/GenderReducer';
import sport from '../modules/sport/SportReducer';
import profile from '../modules/profile/ProfileReducer';
import notification from '../modules/notification/NotificationReducer';
import stats from '../modules/stats/StatsReducer';
import system from '../modules/system/SystemReducer';
import injury from '../modules/injury/InjuryReducer';
import anthropometricData from '../modules/anthropometricData/AnthropometricDataReducer';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  home,
  team,
  category,
  nationality,
  person,
  auth,
  gender,
  sport,
  profile,
  notification,
  stats,
  system,
  injury,
  anthropometricData,
  router: connectRouter(history),
});

const middlewares = [routerMiddleware(history), thunk];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

export const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(...middlewares))
);
