import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import CardActions from '@material-ui/core/CardActions';
import './skillsCard.css';
import { ButtonBase } from '@material-ui/core';
import CustomDialog from '../dialog/CustomDialog';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const TechnicalAttributes = ({ title, subtitle, skillCategoryPlayer }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Card className={classes.card} elevation={3}>
        <ButtonBase onClick={handleClickOpen}>
          <CardHeader
            title={title.toUpperCase()}
            subheader={subtitle}
            style={{ textAlign: 'left' }}
            titleTypographyProps={{ variant: 'display1' }}
          />
        </ButtonBase>

        <CardActions style={{ justifyContent: 'space-between' }}>
          <div>
            <span className="dot green" />
          </div>
          <div>
            <img width="37px" src="/img/profile/left-foot.png" alt="left foot" />
            <img width="37px" src="/img/profile/right-foot.png" alt="right foot" />
          </div>
        </CardActions>
      </Card>
      <CustomDialog
        open={open}
        onClose={handleClose}
        skillCategoryPlayer={skillCategoryPlayer}
        title="atributos tecnicos"
      />
    </>
  );
};

TechnicalAttributes.defaultProps = {
  title: '',
  subtitle: '',
  skillCategoryPlayer: [],
};

TechnicalAttributes.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  skillCategoryPlayer: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
    })
  ),
};

export default TechnicalAttributes;
