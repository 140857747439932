import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CustomAvatar from './Avatar';
import TechnicalAttributes from './card/TechnicalAttributes';
import SoftSkills from './card/SoftSkills';
import Achievements from './card/Achievements';
import TacticalAttributes from './card/TacticalAttributes';
import PhysicalAttributes from './card/PhysicalAttributes';
import CustomLineBarChartWithLegend from './charts/barLineChart/CustomLineBarChartWithLegend';
import Notes from './card/Notes';
import * as profileActions from './ProfileActions';
import * as personActions from '../person/PersonActions';
import {
  teamPlayerSelector,
  RPEPlayerSelector,
  skillCategorySelector,
  technicalAttributesPlayerSelector,
  wellnessPlayerSelector,
  anthropometricPlayerSelector,
} from './ProfileSelectors';
import ImageUploadDialog from '../upload/ImageUploadDialog';
import { BASE_URL } from '../../const/config';
import defaultImage from '../../utils/defaultImage';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  notesContainer: {
    marginTop: '10px',
  },
}));

const Profile = ({
  match: {
    params: { id, idPerson },
  },
  fetchTeamPlayer,
  fetchRPEPlayer,
  fetchSkillCategoryPlayer,
  fetchTechnicalAttributesPlayer,
  fetchWellnessPlayer,
  fetchAnthropometricPlayer,
  fetchWellnessHistoricalPlayer,
  teamPlayer,
  RPEPlayer,
  skillCategoryPlayer,
  technicalAttributesPlayer,
  wellnessPlayer,
  anthropometricPlayer,
  uploadPersonImage,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [wellnessHistoricalPlayer, setWellnessHistorialPlayer] = useState([]);

  useEffect(() => {
    fetchTeamPlayer(id);
    fetchRPEPlayer(id);
    fetchSkillCategoryPlayer(id, 1);
    fetchTechnicalAttributesPlayer(id, 1);
    fetchWellnessPlayer(id);
    fetchAnthropometricPlayer(idPerson);
    fetchWellnessHistoricalPlayer(id).then(
      (data) => isMounted && setWellnessHistorialPlayer(data)
    );
  }, []);

  const onImageUploadSubmit = (image) => {
    uploadPersonImage(teamPlayer.person.id, image).then((res) => {
      fetchTeamPlayer(id);
    });
    setImageUploadDialogOpen(false);
  };
  const onImageUploadClose = () => {
    setImageUploadDialogOpen(false);
  };

  const onRPEReload = (filters) => {
    const { days, offsetCl, cantDaysAl, cantDaysMonotony } = filters;
    return fetchRPEPlayer(id, days, offsetCl, cantDaysAl, cantDaysMonotony);
  };

  const onWellnessReload = (filters) => {
    const { days } = filters;
    return fetchWellnessHistoricalPlayer(id, days);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4} md={3}>
          <Card elevation={3}>
            <CardContent>
              {teamPlayer.person && (
                <div
                  style={{ cursor: 'pointer' }}
                  title="Cargar foto"
                  onClick={() => setImageUploadDialogOpen(true)}
                >
                  <CustomAvatar
                    image={
                      teamPlayer.person.image
                        ? BASE_URL + teamPlayer.person.image
                        : defaultImage()
                    }
                    size="large"
                  />
                </div>
              )}
              {teamPlayer.team && (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  {teamPlayer.team.name}
                </Typography>
              )}
              {teamPlayer.player && teamPlayer.player.position && (
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  {teamPlayer.player.position.description}
                </Typography>
              )}
              {teamPlayer.person && (
                <Typography
                  variant="h6"
                  gutterBottom
                  color="textPrimary"
                  style={{ textAlign: 'center' }}
                >
                  {teamPlayer.person.firstName.toUpperCase()}
                  <span> {teamPlayer.person.lastName.toUpperCase()}</span>
                </Typography>
              )}
            </CardContent>
          </Card>
          <div className={classes.notesContainer}>
            {anthropometricPlayer && anthropometricPlayer[0] ? (
              <PhysicalAttributes
                title="ATRIBUTOS FISICOS"
                weight={anthropometricPlayer[0].weight}
                height={anthropometricPlayer[0].height}
                muscle={anthropometricPlayer[0].muscleMass}
                age={anthropometricPlayer[0].date}
              />
            ) : null}
          </div>
          <div className={classes.notesContainer}>
            <Notes title="Observaciones" />
          </div>
        </Grid>

        <Grid container item xs={8} md={9} spacing={2}>
          <Grid item xs>
            {technicalAttributesPlayer && (
              <TechnicalAttributes
                title="atributos tecnicos" // Esto se podría parametrizar
                subtitle="Delantero" // Esto se podría parametrizar
                skillCategoryPlayer={technicalAttributesPlayer}
              />
            )}
          </Grid>
          <Grid item xs>
            <SoftSkills title="Softskills" subtitle="Habilidades Blandas" />
          </Grid>
          <Grid item xs>
            <Achievements title="Logros" subtitle="Champions League" />
          </Grid>
          <Grid item xs>
            {skillCategoryPlayer && (
              <TacticalAttributes
                title="atributos tacticos" // Esto se podría parametrizar
                subtitle="Temporada 2019" // Esto se podría parametrizar
                skillCategoryPlayer={skillCategoryPlayer}
              />
            )}
          </Grid>
          <Grid container>
            <Grid item style={{ width: '100%', minWidth: '800px' }}>
              <div style={{ padding: '10px 0px' }}>
                <CustomLineBarChartWithLegend
                  title="Wellness Histórico"
                  data={wellnessHistoricalPlayer.data}
                  hasFilters
                  onReload={onWellnessReload}
                  hasArea={false}
                  hasLegend={false}
                  type="wellness"
                />
              </div>
            </Grid>
            <Grid item style={{ width: '100%', minWidth: '800px' }}>
              {RPEPlayer && (
                <div style={{ padding: '10px 0px' }}>
                  <CustomLineBarChartWithLegend
                    title="Carga interna / Carga externa"
                    data={RPEPlayer}
                    hasFilters
                    type='RPE'
                    onReload={onRPEReload}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {teamPlayer.person && (
        <ImageUploadDialog
          open={imageUploadDialogOpen}
          onSubmit={onImageUploadSubmit}
          onClose={onImageUploadClose}
          fileName={`profile_${teamPlayer.person.id}`}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  teamPlayer: teamPlayerSelector(state),
  RPEPlayer: RPEPlayerSelector(state),
  skillCategoryPlayer: skillCategorySelector(state),
  technicalAttributesPlayer: technicalAttributesPlayerSelector(state),
  wellnessPlayer: wellnessPlayerSelector(state),
  anthropometricPlayer: anthropometricPlayerSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...profileActions, ...personActions }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(Profile);
