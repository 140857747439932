import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const PERSON_ACTION_TYPES = {
  FETCH_PEOPLE_SUCCESS: 'FETCH_PEOPLE_SUCCESS',
  PERSON_ERROR: 'PERSON_ERROR',
};

export const fetchPeople = () => dispatch => {
  return fetchMiddleware('GET', '/person/').then(
    json =>
      dispatch({
        type: PERSON_ACTION_TYPES.FETCH_PEOPLE_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};

export const addPerson = person => dispatch => {
  return fetchMiddleware('POST', '/person/', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(person),
  }).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Persona agregada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

export const updatePerson = person => dispatch => {
  return fetchMiddleware('PUT', `/person/${person.id}/`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(person),
  }).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Persona atualizada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

export const deletePerson = id => dispatch => {
  return fetchMiddleware('DELETE', `/person/${id}/`).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Persona eliminada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};

export const uploadPersonImage = (id, image) => dispatch => {
  let formData = new FormData();
  formData.append('image', image, image.name);

  return fetchMiddleware('PUT', `/person/${id}/image/`, {
    method: 'PUT',
    body: formData,
  }).then(
    json => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Imagen cargada correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    error => actionErrorHandler(error, dispatch)
  );
};
