import React, { useState } from 'react';
import InjuryFormWrapper from './InjuryFormWrapper';
import InjuryList from './InjuryList';
import {
  makeStyles,
  Button,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    float:"right",
    marginTop: "15px",
    marginRight: "10px",
    zIndex: 2
  }
}));

const Injuries = ({}) => {
  const classes = useStyles();
  const [injury, setInjury] = useState(null);
  const [mostrarForm, setMostrarForm] = useState(false);

  const handleSetInjury = injury => {
    setInjury(injury);
  };

  return (
    <div>
      {!mostrarForm && <Button
                type="button"
                size="small"
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  setMostrarForm(true);
                }}
              >
                Agregar
      </Button>}
      { mostrarForm && <InjuryFormWrapper injury={injury} setInjury={handleSetInjury} setMostrarForm={setMostrarForm}/>}
      <InjuryList setInjury={handleSetInjury} setMostrarForm={setMostrarForm} />
    </div>
  );
};

export default Injuries;
