import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Line,
  Bar,
  Cell,
  XAxis,
  YAxis,
  BarChart,
  Legend,
  Tooltip,
  Area,
  ResponsiveContainer,
} from 'recharts';

/**
 * @function CustomLineBarComposedChart
 * @param {array} -- data es un arreglo de objetos con 5 valores: clave es para el eje x y valor para el eje y
 * @returns {ComposedChart}
 */

const WellnessCustomComposedChart = ({ data, hasLegend, yAxisLegend }) => {
  const COLORS = ['#3E4095', '#5D60A7'];

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={7}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const toHours = (props) => {
    return `${props}Hs`;
  };
  // console.log('data: ', data);
  return (
    <>
      <ResponsiveContainer width="100%" height={410}>
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          {hasLegend && <Legend verticalAlign="top" height={30} />}
          <Tooltip />

          <XAxis
            dataKey="date"
            tick={<CustomizedAxisTick />}
            height={70}
            interval={0}
            padding={{ left: 20, right: 20 }}
          />
          <Bar
            name="Humor/Talante"
            dataKey="humor.value"
            barSize={20}
            fill="#1087d3"
          />

          {/* <YAxis
            dataKey="value"
            yAxisId="left"
            orientation="left"
            domain={['auto', 'auto']}
          /> */}
          <YAxis
            dataKey="humor.value"
            orientation="left"
            tickFormatter={toHours}
            domain={[0, 10]}
          />

          {/* <Line
            name="Strain"
            type="linear"
            dataKey="strain"
            strokeWidth={3}
            stroke="#FB9A99"
            dot={false}
          />
           */}
          <Line
            name="Nivel de Estrés"
            type="linear"
            dataKey="estres.value"
            strokeWidth={3}
            stroke="#A6CEE3"
            dot={false}
          />

          {/* <YAxis dataKey={null} /> */}
          <Line
            name="Daño Muscular General"
            type="linear"
            dataKey="dolorMuscular.value"
            strokeWidth={3}
            stroke="#FB9A99"
            dot={false}
          />
          <Line
            name="Calidad de Sueño"
            type="linear"
            dataKey="CalidadDeSueño.value"
            strokeWidth={3}
            stroke="#B2DF8A"
            dot={false}
          />
          <Line
            name="Fatiga"
            dot={false}
            type="monotone"
            dataKey="fatiga.value"
            strokeWidth={3}
            stroke="#33A02C"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

WellnessCustomComposedChart.defaultProps = {
  data: [],
  hasLegend: true,
};

WellnessCustomComposedChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      player: PropTypes.string,
      date: PropTypes.string,
      value: PropTypes.number,
      chronicLoad: PropTypes.number,
      acuteLoad: PropTypes.number,
      freshnessIndex: PropTypes.number,
      monotony: PropTypes.number,
      strain: PropTypes.number,
      acwr: PropTypes.number,
    })
  ),
  hasLegend: PropTypes.bool,
};
export default WellnessCustomComposedChart;
