import React, { useState, useEffect } from 'react';
import AnthropometricDataList from './AnthropometricDataList';
import AnthropometricDataFormWrapper from './AnthropometricDataFormWrapper';

import {
    makeStyles,
    Button,
  } from '@material-ui/core';
  
  const useStyles = makeStyles((theme) => ({
    button: {
      float:"right",
      marginTop: "15px",
      marginRight: "10px",
      zIndex: 2
    }
  }));

export const AnthropometricDataScreen = ({

}) => {

  const [showForm,setShowForm] = useState(false);
  const classes = useStyles();
  const [anthropometricData, setAnthropometricData] = useState(null);

  return (    
    <div> 
      {!showForm && <Button
          type="button"
          size="small"
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => {
            setShowForm(true);
          }}
          >
          Agregar
      </Button>}
      { showForm && <AnthropometricDataFormWrapper anthropometricData={anthropometricData} setShowForm={setShowForm} setAnthropometricData={setAnthropometricData} /> }
      <AnthropometricDataList setAnthropometricData={setAnthropometricData} setShowForm={setShowForm}/>
    </div>
  )
}

export default AnthropometricDataScreen;