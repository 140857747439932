import React from 'react';
import PropTypes from 'prop-types';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from 'recharts';

/**
 * @function CustomRadarChart
 * @param {array} -- data es un arreglo de objetos con múltiples valores: clave es para cada arista del radar, 2019 para el valor de ese año, 2018 lo mismo...
 * @returns {RadarChart}
 */

const CustomRadarChart = ({ skillCategoryPlayer }) => {
  return (
    <>
      <RadarChart
        cx={300}
        cy={250}
        outerRadius={150}
        width={600}
        height={500}
        data={skillCategoryPlayer}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="clave" />
        <PolarRadiusAxis />
        <Radar
          dataKey="2019"
          strokeWidth={5}
          stroke="#fff200"
          fill="#fff200"
          fillOpacity={0}
        />
        <Radar
          dataKey="2018"
          strokeWidth={5}
          stroke="#f62a2a"
          fill="#f62a2a"
          fillOpacity={0}
        />
      </RadarChart>
    </>
  );
};

CustomRadarChart.defaultProps = {
  skillCategoryPlayer: [],
};

CustomRadarChart.propTypes = {
  skillCategoryPlayer: PropTypes.arrayOf(
    PropTypes.shape({
      2018: PropTypes.number,
      2019: PropTypes.number,
      clave: PropTypes.string,
    })
  ),
};
export default CustomRadarChart;
