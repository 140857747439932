import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import CustomRadarChart from '../charts/radarChart/CustomRadarChart';

const useStyles = makeStyles(() => ({
  seasonContainer: {
    marginTop: '70px',
  },
  seasonBulletContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
  },
  seasonInfoBulletContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  seasonYear: {
    marginLeft: '5px',
  },
}));

const CustomDialog = ({ onClose, open, skillCategoryPlayer, title }) => {
  const handleClose = () => {
    onClose();
  };

  const classes = useStyles();

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="simple-dialog-title">{title.toUpperCase()}</DialogTitle>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
          }}
        >
          <div>
            <CustomRadarChart skillCategoryPlayer={skillCategoryPlayer.data} />
          </div>
          <div className={classes.seasonContainer}>
            <span>Temporadas</span>
            <div className={classes.seasonBulletContainer}>
              <div className={classes.seasonInfoBulletContainer}>
                <span className="dot yellow" />
                <span className={classes.seasonYear}>2019</span>
              </div>
              <div className={classes.seasonInfoBulletContainer}>
                <span className="dot red" />
                <span className={classes.seasonYear}>2018</span>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

CustomDialog.defaultProps = {
  skillCategoryPlayer: [],
  title: '',
};

CustomDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  skillCategoryPlayer: PropTypes.arrayOf(
    PropTypes.shape({
      2018: PropTypes.number,
      2019: PropTypes.number,
      clave: PropTypes.string,
    })
  ),
};

export default CustomDialog;
