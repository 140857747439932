import { SPORT_ACTION_TYPES } from './SportActions';

const initialState = {
  sports: [],
  error: '',
};

export default function sport(state = initialState, action) {
  switch (action.type) {
    case SPORT_ACTION_TYPES.FETCH_SPORTS_SUCCESS:
      return Object.assign({}, state, {
        sports: action.payload,
      });
    case SPORT_ACTION_TYPES.SPORT_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
