import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { bindActionCreators, compose } from 'redux';
import { notificationsSelector } from './NotificationSelector';
import * as notificationActions from './NotificationActions';

let displayed = [];

const Notifier = ({ notifications, removeSnackbar }) => {
  const { enqueueSnackbar } = useSnackbar();

  const storeDisplayed = id => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = id => {
    displayed = [...displayed.filter(key => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {} }) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          removeSnackbar(myKey);
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, enqueueSnackbar, removeSnackbar]);

  return null;
};

const mapStateToProps = state => ({
  notifications: notificationsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...notificationActions,
    },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Notifier);
