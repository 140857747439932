import errors from '../const/errors';
import { ERROR_MESSAGES } from '../const/error-messages';

export const errorHandler = response => {
  return new Promise((resolve, reject) => {
    switch (response.status) {
      case 500:
        resolve(ERROR_MESSAGES['500']);
        break;
      case 400: 
        resolve(
          response.json().then(res => {
            if (
              res.error_de_validacion &&
              Object.keys(res.error_de_validacion).length > 0
            ) {
              return ERROR_MESSAGES.validation;
            } else if (
              res.error_de_negocio &&
              (errors[res.error_de_negocio] || errors[res.error_de_negocio[0]])
            ) {
              return errors[res.error_de_negocio] || errors[res.error_de_negocio[0]];
            }
            return ERROR_MESSAGES['400'];
          })
        );
        break;
      case 401:
        resolve(ERROR_MESSAGES['401']);
        break;
      case 403:
        resolve(ERROR_MESSAGES['403']);
        break;
      default:
        resolve(ERROR_MESSAGES['400']);
    }
  });
};
