import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as statsActions from './StatsActions';
import * as teamActions from '../team/TeamActions';
import { teamRPESelector, teamRPEWeekSelector } from './StatsSelectors';
import CustomLineBarChartWithLegend from '../profile/charts/barLineChart/CustomLineBarChartWithLegend';
import { positionsSelector } from '../team/TeamSelectors';
import LoadingSpinner from '../shared/LoadingSpinner';

const RpeStats = ({
  fetchTeamRPE,
  teamRPE,
  fetchTeamRPEByPosition,
  fetchPositions,
  positions,
  id,
}) => {
  const [fetch, setFetch] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTeamRPE(id);
    fetchPositions();
  }, [setFetch]);

  const [allTeamRPEByPosition, setAllTeamRPEByPosition] = useState([]);

  /**
   * Retorna un arreglo de promesas con los datos necesarios para los graficos
   * por posición. (position y data)
   * @param positionsList
   * @param filters Si está presente seran los filtros aplicados, si no utiliza los default
   * @returns {*}
   */
  const getRPEByPositionPromises = (positionsList, filters = {}) => {
    const { days, offsetCl, cantDaysAl, cantDaysMonotony } = filters;
    return positionsList.map((position) => {
      return fetchTeamRPEByPosition(
        id,
        position.description,
        days,
        offsetCl,
        cantDaysAl,
        cantDaysMonotony
      ).then((res) => {
        return {
          position,
          data: res,
        };
      });
    });
  };

  const onRPEReload = (filters) => {
    const { days, offsetCl, cantDaysAl, cantDaysMonotony } = filters;
    setLoading(true);
    Promise.all(getRPEByPositionPromises(positions, filters))
      .then((responses) => {
        setAllTeamRPEByPosition(responses);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    return fetchTeamRPE(id, days, offsetCl, cantDaysAl, cantDaysMonotony);
  };


  /**
   * Cuando se tienen las posiciones, se piden los RPE por cada una y luego
   * se utilizan las respuestas combinadas para mostrar los graficos
   */
  useEffect(() => {
    if (positions && positions.length > 0) {
      Promise.all(getRPEByPositionPromises(positions))
        .then((responses) => {
          setAllTeamRPEByPosition(responses);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [positions]);

  return (
    <div>
      {teamRPE && (
        <div style={{ padding: '10px 0px' }}>
          <CustomLineBarChartWithLegend
            title="Carga interna / Carga externa"
            data={teamRPE}
            type='RPE'
            hasFilters={true}
            onReload={onRPEReload}
          />
        </div>
      )}
      
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {allTeamRPEByPosition.map((teamRPEByPosition) => {
            return (
              <div
                style={{ padding: '10px 0px' }}
                key={teamRPEByPosition.position.id}
              >
                <CustomLineBarChartWithLegend
                  type='RPE'
                  title={`Carga interna / Carga externa - Posición: ${teamRPEByPosition.position.description}`}
                  data={teamRPEByPosition.data}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  teamRPE: teamRPESelector(state),
  teamRPEWeek: teamRPEWeekSelector(state),
  positions: positionsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...statsActions, ...teamActions }, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(RpeStats);
