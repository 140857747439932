import React from 'react';
import { Provider } from 'react-redux';
import { store, history } from './store/Store';
import { ThemeProvider } from '@material-ui/styles';
import theme from './styles/theme';
import AppWrapper from './modules/commons/AppWrapper';
import { SnackbarProvider } from 'notistack';
import Notifier from './modules/notification/Notifier';

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <Notifier/>
        <AppWrapper/>
      </SnackbarProvider>
    </Provider>
  </ThemeProvider>
);

export default App;
