import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { peopleSelector } from '../person/PersonSelector';
import * as personActions from '../person/PersonActions';
import * as teamActions from '../team/TeamActions';
import {
  positionsSelector,
  teamDetailSelector,
  teamRolesSelector,
} from '../team/TeamSelectors';
import ImageUpload from './ImageUpload';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageUploadDialog = ({ open, onSubmit, onClose, fileName }) => {
  const [image, setImage] = useState(null);

  function handleSubmit() {
    onSubmit(image);
  }

  function handleClose() {
    onClose();
    onClose();
  }

  const imageChange = newImage => {
    setImage(newImage);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xl"
        TransitionComponent={Transition}
        fullScreen
      >
        <DialogTitle>Cargar imagen</DialogTitle>
        <DialogContent>
          <ImageUpload onImageChange={imageChange} fileName={fileName} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => handleSubmit()}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({
  people: peopleSelector(state),
  teamRoles: teamRolesSelector(state),
  teamDetail: teamDetailSelector(state),
  positions: positionsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...personActions,
      ...teamActions,
    },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ImageUploadDialog);
