import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
    Button, 
    MenuItem,
    makeStyles,
    TextField,
} from '@material-ui/core';
import {
  DEFAULT_WEEKS,
  DEFAULT_START_WEEK,
} from '../../../../const/chart';

const FiltersSchema = Yup.object().shape({
    cantWeek: Yup.number()
      .min(DEFAULT_WEEKS.MIN, 'Inválido')
      .max(DEFAULT_WEEKS.MAX, 'Inválido')
      .required('Requerido'),
});

const useStyles = makeStyles(theme => ({
    form: {
      height: '100%',
      width: '800px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    select:{
        height: '45px',
        minWidth: '150px',
    }
  }));

const ChartFiltersPerWeek = ({ onSubmit }) => {
    const classes = useStyles();

    return (
        <Formik
        initialValues={{
            cantWeek: DEFAULT_WEEKS.VALUE,
            startWeek: DEFAULT_START_WEEK.VALUE,
        }}
        onSubmit={(values, { setSubmitting }) => {
            onSubmit(values).then(() => {
            setSubmitting(false);
            });
        }}
        validationSchema={FiltersSchema}
        >
        {({ handleSubmit, isSubmitting, isValid }) => (
            <Form className={classes.form} onSubmit={handleSubmit} spacing={4} >
                <Field name="cantWeek">
                    {({
                        field: { name, value, onChange, onBlur },
                        form: { errors },
                        meta,
                    }) => (
                    <TextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label="Semanas"
                        variant="outlined"
                        size="small"
                        style={{ width: '70px' }}
                        type="number"
                        error={!!errors.cantWeek}
                        helperText={errors.cantWeek || ' '}
                    />
                    )}
                </Field>
                <Field name="startWeek">
                    {({
                        field: { name, value, onChange, onBlur },
                        form: { errors },
                        meta,
                    }) => (
                    <TextField
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label="Inicio de semana"
                        variant="outlined"
                        size="small"
                        style={{ width: '150px', marginRight:'30px', marginLeft:'37px' }}
                        select
                    >
                        <MenuItem value={"Monday"}>Lunes</MenuItem>
                        <MenuItem value={"Sunday"}>Domingo</MenuItem>
                    </TextField>
                    )}
                </Field>
                <Button
                    style={{ marginTop: '5px' }}
                    color={'primary'}
                    variant={'contained'}
                    size={'small'}
                    type={'submit'}
                    disabled={!isValid || isSubmitting}
                >
                    Aplicar
                </Button>
            </Form>
      )}
    </Formik>
  );
};

ChartFiltersPerWeek.propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  
  export default ChartFiltersPerWeek;
