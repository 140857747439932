import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CardActions from '@material-ui/core/CardActions';
import './skillsCard.css';
import { ButtonBase } from '@material-ui/core';

import CustomDialog from '../dialog/CustomDialog';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const TacticalAttributes = ({ title, subtitle, skillCategoryPlayer }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <Card className={classes.card} elevation={3}>
        <ButtonBase onClick={handleClickOpen}>
          <CardHeader
            title={title.toUpperCase()}
            subheader={subtitle}
            style={{ textAlign: 'left' }}
            titleTypographyProps={{ variant: 'display1' }}
          />
        </ButtonBase>

        <CardActions style={{ justifyContent: 'space-between' }}>
          <div>
            <span className="dot green" />
            <span className="dot green" />
            <span className="dot green" />
            <span className="dot yellow" />
          </div>
          <div>
            <TrendingUpIcon fontSize="large" />
          </div>
        </CardActions>
      </Card>
      <CustomDialog
        open={open}
        onClose={handleClose}
        skillCategoryPlayer={skillCategoryPlayer}
        title="atributos tacticos"
      />
    </>
  );
};

TacticalAttributes.defaultProps = {
  title: '',
  subtitle: '',
  skillCategoryPlayer: [],
};

TacticalAttributes.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  skillCategoryPlayer: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.object,
    })
  ),
};

export default TacticalAttributes;
