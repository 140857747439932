import { AUTH_ACTION_TYPES } from './AuthActions';

const initialState = {
  isLoading: true,
  user: {},
  person: {},
  isSignedIn: false,
  token: localStorage.getItem('token') || null,
  roles: [],
  currentRole: {},
  permissions: [],
  error: '',
};

export default function auth (state = initialState, action) {
  switch (action.type) {
    case AUTH_ACTION_TYPES.GOOGLE_AUTH_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload,
      });
    case AUTH_ACTION_TYPES.AUTH_SUCCESS:
      const { key, person, groups } = action.payload;
      return Object.assign({}, state, {
        token: key,
        person: person,
        roles: groups,
        isSignedIn: true,
        isLoading: false,
      });
    case AUTH_ACTION_TYPES.GOOGLE_LOGOUT_SUCCESS: {
      return Object.assign({}, state, initialState, {
        token: null,
        person: {},
        roles: [],
        isSignedIn: false,
      });
    }
    case AUTH_ACTION_TYPES.USER_UNAUTHORIZED: {
      return Object.assign({}, state, initialState, {
        token: null,
        person: {},
        roles: [],
        error: action.msg,
        isLoading: false,
        isSignedIn: false,
      });
    }
    case AUTH_ACTION_TYPES.AUTH_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
        isLoading: false,
      });
    default:
      return state;
  }
}
