import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const SYSTEM_ACTION_TYPES = {
  FETCH_SYSTEM_TAG_SUCCESS: 'FETCH_SYSTEM_TAG_SUCCESS',
};

export const fetchSystemTag = () => dispatch => {
  return fetchMiddleware('GET', '/system/tag/').then(
    json =>
      dispatch({
        type: SYSTEM_ACTION_TYPES.FETCH_SYSTEM_TAG_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch));
};
