import { fetchMiddleware } from '../../utils/fetchMiddleware';
import {
  DEFAULT_DAYS,
  DEFAULT_DAYS_AL,
  DEFAULT_DAYS_MONOTONY,
  DEFAULT_OFFSET_CL,
} from '../../const/chart';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const PROFILE_ACTION_TYPES = {
  FETCH_TEAM_PLAYER_SUCCESS: 'FETCH_TEAM_PLAYER_SUCCESS',
  FETCH_RPE_PLAYER_SUCCESS: 'FETCH_RPE_PLAYER_SUCCESS',
  FETCH_SKILL_CATEGORY_PLAYER: 'FETCH_SKILL_CATEGORY_PLAYER',
  FETCH_TECHNICAL_ATTRIBUTES_PLAYER: 'FETCH_TECHNICAL_ATTRIBUTES_PLAYER',
  FETCH_WELLNESS_PLAYER: 'FETCH_WELLNESS_PLAYER',
  FETCH_ANTHROPOMETRIC_PLAYER: 'FETCH_ANTHROPOMETRIC_PLAYER',
  FETCH_WELLNESS_HISTORICAL_PLAYER_SUCCESS:
    'FETCH_WELLNESS_HISTORICAL_PLAYER_SUCCESS',
  FETCH_WELLNESS_HISTORICAL_PLAYER_ERROR:
    'FETCH_WELLNESS_HISTORICAL_PLAYER_ERROR',
  TEAM_ERROR: 'TEAM_ERROR',
};

export const fetchTeamPlayer = (id) => (dispatch) => {
  return fetchMiddleware('GET', `/member/${id}/`).then(
    (json) =>
      dispatch({
        type: PROFILE_ACTION_TYPES.FETCH_TEAM_PLAYER_SUCCESS,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchRPEPlayer = (
  idMember,
  days = DEFAULT_DAYS.VALUE,
  offsetCl = DEFAULT_OFFSET_CL.VALUE,
  cantDaysAl = DEFAULT_DAYS_AL.VALUE,
  cantDaysMonotony = DEFAULT_DAYS_MONOTONY.VALUE
) => (dispatch) => {
  return fetchMiddleware(
    'GET',
    `/evaluation/rpe_member/?idMember=${idMember}&days=${days}&offsetCl=${offsetCl}&cantDaysAl=${cantDaysAl}&cantDaysMonotony=${cantDaysMonotony}`
  ).then(
    (json) =>
      dispatch({
        type: PROFILE_ACTION_TYPES.FETCH_RPE_PLAYER_SUCCESS,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchSkillCategoryPlayer = (idMember, skill) => (dispatch) => {
  return fetchMiddleware(
    'GET',
    `/member/${idMember}/skill_category/${skill}/`
  ).then(
    (json) =>
      dispatch({
        type: PROFILE_ACTION_TYPES.FETCH_SKILL_CATEGORY_PLAYER,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchTechnicalAttributesPlayer = (idMember, skill) => (
  dispatch
) => {
  return fetchMiddleware(
    'GET',
    `/member/${idMember}/skill_category/${skill}/`
  ).then(
    (json) =>
      dispatch({
        type: PROFILE_ACTION_TYPES.FETCH_TECHNICAL_ATTRIBUTES_PLAYER,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchWellnessPlayer = (id) => (dispatch) => {
  return fetchMiddleware(
    'GET',
    `/evaluation/wellness_member/?idMember=${id}`
  ).then(
    (json) =>
      dispatch({
        type: PROFILE_ACTION_TYPES.FETCH_WELLNESS_PLAYER,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchAnthropometricPlayer = (id) => (dispatch) => {
  return fetchMiddleware(
    'GET',
    `/anthropometric/person/?idPerson=${id}&last=False`
  ).then(
    (json) =>
      dispatch({
        type: PROFILE_ACTION_TYPES.FETCH_ANTHROPOMETRIC_PLAYER,
        payload: json,
      }),
    (error) => actionErrorHandler(error, dispatch)
  );
};

export const fetchWellnessHistoricalPlayer = (
  idMember,
  days = DEFAULT_DAYS.VALUE
) => async (dispatch) => {
  try {
    const response = await fetchMiddleware(
      'GET',
      `/evaluation/wellness_member_history/?idMember=${idMember}&days=${days}`
    );
    response
      ? dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_WELLNESS_HISTORICAL_PLAYER_SUCCESS,
          payload: response,
        })
      : dispatch({
          type: PROFILE_ACTION_TYPES.FETCH_WELLNESS_HISTORICAL_PLAYER_ERRROR,
        });
    return response;
  } catch (error) {
    actionErrorHandler(error, dispatch);
  }
};
