import { NATIONALITY_ACTION_TYPES } from './NationalityActions';

const initialState = {
  nationalities: [],
  error: '',
};

export default function nationality(state = initialState, action) {
  switch (action.type) {
    case NATIONALITY_ACTION_TYPES.FETCH_NATIONALITIES_SUCCESS:
      return Object.assign({}, state, {
        nationalities: action.payload,
      });
    case NATIONALITY_ACTION_TYPES.NATIONALITY_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
