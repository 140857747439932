import React, { useEffect, useState } from 'react';
import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as teamActions from '../team/TeamActions';
import * as statsActions from './StatsActions';
import { teamDetailSelector } from '../team/TeamSelectors';
import { teamRPEWeekSelector } from './StatsSelectors';
import CustomLineBarChartWithLegend from '../profile/charts/barLineChart/CustomLineBarChartWithLegend';
import Typography from '@material-ui/core/Typography';
import RpeStats from './RpeStats';
import WellnessStats from './WellnessStats';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
  title: {
    margin: '10px 0 10px 0',
  },
}));

const SquadStats = ({
                      match: {
                        params: { id },
                      },
                      fetchTeam,
                      teamDetail,
                      teamRPEWeek,
                      fetchTeamRPEWeek,
                      history,
                    }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchTeam(id);
    fetchTeamRPEWeek(id);
  }, []);

  const onRPEWeekReload = (filters) => {
    const { cantWeek, startWeek } = filters;
    return fetchTeamRPEWeek(id, cantWeek, startWeek);
  }

  const goToProfile = (memberId, personId) => {
    history.push(`/profile/${memberId}/${personId}`);
  };

  return (
    <div className={classes.container}>
      <div>
        {teamDetail && (
          <div>
            <Typography className={classes.title} variant="h4">
              {teamDetail.name}
            </Typography>
          </div>
        )}
        <Grid container xs={12}>
          <Grid xs={6}>
            <WellnessStats id={id} goToProfile={goToProfile}/>
          </Grid>
          <Grid xs={6}>
            {teamRPEWeek && (
            <div style={{ padding: '10px 0px' }}>
              <CustomLineBarChartWithLegend
                title="Carga interna / Carga externa Semanal"
                type='RPEWeek'
                data={teamRPEWeek}
                hasFilters={true}
                onReload={onRPEWeekReload}
              />
            </div>
          )}
          </Grid>
        </Grid>
        <RpeStats id={id}/>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  teamDetail: teamDetailSelector(state),
  teamRPEWeek: teamRPEWeekSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...statsActions,...teamActions }, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SquadStats);
