import { AUTH_ACTION_TYPES } from '../modules/auth/AuthActions';
import { ERROR_MESSAGES } from '../const/error-messages';

export const actionErrorHandler = (error, dispatch) => {
  if (error === ERROR_MESSAGES['401']) {
    return dispatch({ type: AUTH_ACTION_TYPES.USER_UNAUTHORIZED, msg: error });
  }
  return dispatch({
    type: 'ENQUEUE_SNACKBAR',
    notification: {
      message: error,
      key: new Date().getTime() + Math.random(),
      options: {
        variant: 'error',
        autoHideDuration: 3000,
        disableWindowBlurListener: true,
      },
    },
  });
};
