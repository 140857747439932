import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardActions } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import ChartFilters from '../chartFilters/ChartFilters';
import ChartFiltersPerWeek from '../chartFilters/ChartFiltersPerWeek';
import WellnessCustomComposedChart from './WellnessCustomComposedChart';
import RPECustomComposedChart from './RPECustomComposedChart';
import RPEWeekCustomComposedChart from './RPEWeekCustomComposedChart'

const CustomLineBarChartWithLegend = ({
  title,
  data,
  hasFilters,
  onReload,
  type,
}) => {
  const onFiltersChange = filters => {
    return onReload(filters);
  };

  const typeOptions = {
    'wellness': {'graphic': <WellnessCustomComposedChart data={data} />, 'filters': <ChartFilters onSubmit={onFiltersChange} />},
    'RPE' : {'graphic': <RPECustomComposedChart data={data} />, 'filters': <ChartFilters onSubmit={onFiltersChange} />},
    'RPEWeek': {'graphic': <RPEWeekCustomComposedChart data={data} />, 'filters': <ChartFiltersPerWeek onSubmit={onFiltersChange} />},
  }
  return (
    <Card elevation={3}>
      <CardHeader title={title.toUpperCase()} />
      {hasFilters && (
        <CardContent
          style={{ padding: '0', display: 'flex', justifyContent: 'center' }}
        >
          {typeOptions[type].filters} 
        </CardContent>
      )}
      <CardActions style={{ justifyContent: 'space-between' }}>
        {typeOptions[type].graphic}
      </CardActions>
    </Card>
  );
};

CustomLineBarChartWithLegend.defaultProps = {
  title: '',
  data: [],
};

CustomLineBarChartWithLegend.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      player: PropTypes.string,
      date: PropTypes.string,
      value: PropTypes.number,
      chronicLoad: PropTypes.number,
      acuteLoad: PropTypes.number,
      freshnessIndex: PropTypes.number,
      monotony: PropTypes.number,
      strain: PropTypes.number,
      acwr: PropTypes.number,
    })
  ),
};
export default CustomLineBarChartWithLegend;
