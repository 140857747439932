import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#9bc947' },
    primary: {
      light: '#303f9f',
      main: '#303f9f',
      dark: '#303f9f',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#cefc78',
      main: '#9bc947',
      dark: '#69980e',
      contrastText: '#000000',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});
