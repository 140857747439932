import { PERSON_ACTION_TYPES } from './PersonActions';

const initialState = {
  people: [],
  error: '',
};

export default function person(state = initialState, action) {
  switch (action.type) {
    case PERSON_ACTION_TYPES.FETCH_PEOPLE_SUCCESS:
      return Object.assign({}, state, {
        people: action.payload,
      });
    case PERSON_ACTION_TYPES.PERSON_ERROR:
      return Object.assign({}, state, {
        error: action.msg,
      });
    default:
      return state;
  }
}
