import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { peopleSelector } from '../person/PersonSelector';
import * as personActions from '../person/PersonActions';
import * as teamActions from '../team/TeamActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import {
  positionsSelector,
  teamDetailSelector,
  teamRolesSelector,
} from '../team/TeamSelectors';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';

const AddMemberDialog = ({
  open,
  onSubmit,
  onClose,
  teamDetail,
  fetchPeople,
  people,
  fetchTeamRoles,
  teamRoles,
  fetchPositions,
  positions,
  addMember,
}) => {
  const [person, setPerson] = React.useState('');
  const [role, setRole] = React.useState('');
  const [position, setPosition] = React.useState('');
  const [number, setNumber] = React.useState(undefined);

  const [initialDate, setInitialDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  const [disableSubmit, setDisableSubmit] = React.useState(false);

  // Una vez que está disponible, se utiliza la fecha de finalización del equipo
  useEffect(() => {
    if (teamDetail.id) {
      setEndDate(new Date(moment(teamDetail.endDate).toISOString()));
    }
  }, [teamDetail]);

  useEffect(() => {
    if (open && people.length === 0) {
      fetchPeople();
      fetchTeamRoles();
      fetchPositions();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    setDisableSubmit(true);
    const newMember = {
      team: teamDetail.id,
      roleTeam: role,
      person: person.id,
      initialDate: moment(initialDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };
    if (teamRoles.find(r => r.id === role).group === 'player') {
      if (position) {
        newMember.position = position;
      }
      if (number) {
        newMember.number = number;
      }
    }
    addMember(newMember)
      .then(() => {
        setDisableSubmit(false);
        handleClose();
        onSubmit();
      })
      .catch(err => {
        // TODO manejo de error
        console.log(err);
        setDisableSubmit(false);
      });
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    stringify: option => `${option.firstName} ${option.lastName}`,
  });

  const handleDateError = (error, value) => {
    if (error) setDisableSubmit(true);
  };

  const handleDateAccept = () => {
    setDisableSubmit(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth={true}>
        <DialogTitle>Agregar miembro</DialogTitle>
        <DialogContent>
          <Autocomplete
            style={{ margin: '10px 0 10px 0' }}
            filterOptions={filterOptions}
            size={'medium'}
            options={people}
            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
            renderInput={params => (
              <TextField
                {...params}
                label="Persona"
                variant="outlined"
                fullWidth
              />
            )}
            onChange={(event, value) => setPerson(value)}
          />
          <TextField
            style={{ margin: '10px 0 10px 0' }}
            select
            value={role}
            onChange={e => setRole(e.target.value)}
            fullWidth
            variant="outlined"
            label="Rol"
          >
            {teamRoles.map(r => (
              <MenuItem key={r.id} value={r.id}>
                {r.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ margin: '10px 0 10px 0' }}
            select
            value={position}
            onChange={e => setPosition(e.target.value)}
            fullWidth
            variant="outlined"
            label="Posición"
            disabled={
              !role ||
              (role && teamRoles.find(r => r.id === role).group !== 'player')
            }
          >
            {positions.map(p => (
              <MenuItem key={p.id} value={p.id}>
                {p.description}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="number"
            inputProps={{ min: '0', max: '99', step: '1' }}
            style={{ margin: '10px 0 10px 0' }}
            value={number}
            onChange={e => setNumber(e.target.value)}
            fullWidth
            variant="outlined"
            label="Número"
            disabled={
              !role ||
              (role && teamRoles.find(r => r.id === role).group !== 'player')
            }
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              label="Fecha inicio"
              value={initialDate}
              onChange={e => {
                handleDateAccept();
                setInitialDate(e);
              }}
              invalidDateMessage="Formato inválido"
              onError={handleDateError}
            />
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              label="Fecha fin"
              value={endDate}
              onChange={e => {
                handleDateAccept();
                setEndDate(e);
              }}
              invalidDateMessage="Formato inválido"
              onError={handleDateError}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary">
            Cancelar
          </Button>
          <Button
            disabled={
              !person || !role || !initialDate || !endDate || disableSubmit
            }
            onClick={() => handleSubmit()}
            color="primary"
            variant="contained"
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({
  people: peopleSelector(state),
  teamRoles: teamRolesSelector(state),
  teamDetail: teamDetailSelector(state),
  positions: positionsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...personActions,
      ...teamActions,
    },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AddMemberDialog);
