import { INSTANCE } from '../const/config';

/**
 * En base a la instancia actual retorna el nombre de la instancia que maneja machi-gatekeeper
 * @returns {string}
 */
export default function defaultInstance() {
  switch (INSTANCE) {
    case 'prod':
      return 'Prod';
    case 'gelp':
      return 'GELP';
    default:
      return 'Dev';
  }
}
