import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const CATEGORY_ACTION_TYPES = {
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  CATEGORY_ERROR: 'CATEGORY_ERROR',
};

export const fetchCategories = () => dispatch => {
  return fetchMiddleware('GET', '/category/').then(
    json =>
      dispatch({ type: CATEGORY_ACTION_TYPES.FETCH_CATEGORIES_SUCCESS, payload: json }),
    error => actionErrorHandler(error, dispatch)
  );
};
