import React from 'react';
import logo from '../../assets/logo.png';

const styles = {
  home: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Home = () => {
  return (
    <div style={styles.home}>
      <img width="300px" src={logo} />
    </div>
  );
};

export default Home;
