import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const ANTHROPOMETRIC_DATA_ACTION_TYPES = {
  FETCH_ANTHROPOMETRIC_DATA_SUCCESS: 'FETCH_ANTHROPOMETRIC_DATA_SUCCESS',
}
// FETCH 
export const fetchAnthropometricData = (id) => {
    return fetchMiddleware('GET', `/anthropometric/${id}/`);
  };

export const fetchAllAnthropometricData = () => (dispatch) => {
  return fetchMiddleware('GET', '/anthropometric/').then(
    (json) => {
      dispatch({
        type: ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ANTHROPOMETRIC_DATA_SUCCESS,
        payload: json,
      })},
    (error) => actionErrorHandler(error, dispatch)
    );
};

export const fetchAllStage = () => {
  return fetchMiddleware('GET', `/stage/`);
};

export const fetchAllMadurityIndex = () => {
  return fetchMiddleware('GET', `/madurity_index/`);
};

export const fetchAllPurpose = () => {
  return fetchMiddleware('GET', `/purpose/`);
};

// PUT
export const updateAnthropometricData = (anthropometricData, id) => (dispatch) => {
  return fetchMiddleware('PUT', `/anthropometric/${id}/`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(anthropometricData),
  }).then(
    (json) => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Datos antropométricos actualizados correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    (error) => actionErrorHandler(error, dispatch)
  );
};
// POST 
export const addAnthropometricData = (anthropometricData) => (dispatch) => {
  return fetchMiddleware('POST', `/anthropometric/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(anthropometricData),
  }).then(
    (json) => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Datos antropométricos guardados correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    (error) => actionErrorHandler(error, dispatch)
  );
};
// DELETE

export const deleteAnthropometricData = (id) => (dispatch) => {
  return fetchMiddleware('DELETE', `/anthropometric/${id}/`).then(
    (json) => {
      dispatch({
        type: 'ENQUEUE_SNACKBAR',
        notification: {
          message: 'Datos Antropométricos eliminados correctamente',
          key: new Date().getTime() + Math.random(),
          options: { variant: 'success' },
        },
      });
      return json;
    },
    (error) => actionErrorHandler(error, dispatch)
  );
};

