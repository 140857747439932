import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const NATIONALITY_ACTION_TYPES = {
  FETCH_NATIONALITIES_SUCCESS: 'FETCH_NATIONALITIES_SUCCESS',
  NATIONALITY_ERROR: 'NATIONALITY_ERROR',
};

export const fetchNationalities = () => dispatch => {
  return fetchMiddleware('GET', '/nationality/').then(
    json =>
      dispatch({
        type: NATIONALITY_ACTION_TYPES.FETCH_NATIONALITIES_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};
