import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import { translations } from '../../../const/table-localization';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import * as nationalityActions from '../../nationality/NationalityActions';
import * as personActions from '../PersonActions';
import * as genderActions from '../../gender/GenderActions';
import * as sportActions from '../../sport/SportActions';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { peopleSelector } from '../PersonSelector';
import { gendersSelector } from '../../gender/GenderSelectors';
import { nationalitiesSelector } from '../../nationality/NationalitySelector';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { sportsSelector } from '../../sport/SportSelectors';
import SelectMultiple from '../../shared/SelectMultiple';
import { withSnackbar } from 'notistack';
import { BASE_URL } from '../../../const/config';
import ImageUploadDialog from '../../upload/ImageUploadDialog';
import defaultImage from '../../../utils/defaultImage';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
  },
  select: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const PeopleList = ({
  fetchNationalities,
  nationalities,
  fetchGenders,
  genders,
  fetchSports,
  sports,
  fetchPeople,
  people,
  addPerson,
  updatePerson,
  deletePerson,
  uploadPersonImage,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(20);
  const [fetch, setFetch] = useState(null);

  const tableOptions = {
    search: false,
    sorting: true,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    paging: false,
    addRowPosition: 'first',
  };

  useEffect(() => {
    fetchNationalities();
    fetchGenders();
    fetchPeople();
    fetchSports();
  }, [fetch]);

  const columns = [
    {
      field: 'image',
      title: '',
      editable: 'never',
      render: (rowData) => {
        return (
          <div>
            {rowData && (
              <img
                src={rowData.image ? BASE_URL + rowData.image : defaultImage()}
                style={{ width: 50, borderRadius: '50%' }}
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Nombre',
      field: 'firstName',
      editComponent: (props) => (
        <FormControl fullWidth>
          <TextField
            className={classes.rootField}
            placeholder="Nombre *"
            type="text"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Apellido',
      field: 'lastName',
      editComponent: (props) => (
        <FormControl fullWidth>
          <TextField
            className={classes.rootField}
            placeholder="Apellido *"
            type="text"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Email',
      field: 'email',
      editComponent: (props) => (
        <FormControl fullWidth>
          <TextField
            className={classes.rootField}
            placeholder="Email *"
            type="email"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Fecha de nacimiento',
      field: 'birthOfDate',
      render: (rowData) => moment(rowData.birthOfDate).format('DD-MM-YYYY'),
      type: 'date',
      initialEditValue: new Date(),
      cellStyle: {
        width: '150px',
      },
    },
    {
      title: 'Género',
      field: 'gender.id',
      render: (rowData) => rowData.gender.description,
      editComponent: (props) => {
        return (
          <TextField
            select
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            className={classes.select}
          >
            {genders.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.description}
              </MenuItem>
            ))}
          </TextField>
        );
      },
      cellStyle: {
        width: '200px',
      },
    },
    {
      title: 'Nacionalidad',
      field: 'nationality.id',
      render: (rowData) => rowData.nationality.description,
      editComponent: (props) => {
        return (
          <TextField
            select
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            className={classes.select}
          >
            {nationalities.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.description}
              </MenuItem>
            ))}
          </TextField>
        );
      },
      cellStyle: {
        width: '200px',
      },
    },
    {
      title: 'Deportes',
      field: 'sports',
      render: (rowData) => (
        <List dense={true}>
          {rowData.sports.map((sport) => (
            <ListItem key={`item-${sport.id}`}>
              <ListItemText primary={sport.name} />
            </ListItem>
          ))}
        </List>
      ),
      initialEditValue: [],
      editComponent: (props) => (
        <SelectMultiple
          value={props.value}
          onChange={props.onChange}
          options={sports}
        />
      ),
      cellStyle: {
        width: '200px',
      },
    },
  ];

  const [imageUploadDialogOpen, setImageUploadDialogOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState({});

  const onImageUploadSubmit = (image) => {
    uploadPersonImage(selectedPerson.id, image).then(() => {
      fetchPeople();
    });
    setImageUploadDialogOpen(false);
  };
  const onImageUploadClose = () => {
    setImageUploadDialogOpen(false);
  };

  return (
    <div className={classes.container}>
      <ImageUploadDialog
        open={imageUploadDialogOpen}
        onSubmit={onImageUploadSubmit}
        onClose={onImageUploadClose}
        fileName={`profile_${selectedPerson.id}`}
      />
      <MaterialTable
        title={
          <Typography variant="h5" color="textPrimary" align="center">
            Listado de personas
          </Typography>
        }
        onChangeRowsPerPage={(value) => {
          setPageSize(value);
        }}
        columns={columns}
        options={tableOptions}
        localization={translations}
        data={people}
        actions={[
          {
            onClick: (event, rowData) => {},
            icon: 'list_alt',
            tooltip: 'Ver detalle',
          },
          {
            onClick: (event, rowData) => {
              setSelectedPerson(rowData);
              setImageUploadDialogOpen(true);
            },
            icon: 'add_a_photo',
            tooltip: 'Cargar foto',
          },
        ]}
        editable={{
          isEditable: (rowData) => true,
          isDeletable: (rowData) => true,
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              const {
                firstName,
                lastName,
                email,
                birthOfDate,
                gender,
                nationality,
                sports,
              } = newData;
              if (
                !firstName ||
                !lastName ||
                !email ||
                !birthOfDate ||
                !gender ||
                !nationality ||
                !sports
              ) {
                reject();
              } else {
                addPerson({
                  firstName,
                  lastName,
                  email,
                  birthOfDate: moment(birthOfDate).format('YYYY-MM-DD'),
                  gender: gender.id,
                  nationality: nationality.id,
                  addSports: sports,
                }).then((res) => {
                  fetchPeople();
                  resolve();
                });
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const {
                id,
                firstName,
                lastName,
                email,
                birthOfDate,
                gender,
                nationality,
                sports,
              } = newData;
              if (
                !id ||
                !firstName ||
                !lastName ||
                !email ||
                !birthOfDate ||
                !gender ||
                !nationality ||
                !sports
              ) {
                reject();
              } else {
                updatePerson({
                  id,
                  firstName,
                  lastName,
                  email,
                  birthOfDate: moment(birthOfDate).format('YYYY-MM-DD'),
                  gender: gender.id,
                  nationality: nationality.id,
                  addSports: sports,
                }).then((res) => {
                  fetchPeople();
                  resolve();
                });
              }
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deletePerson(oldData.id).then((res) => {
                fetchPeople();
                resolve();
              });
            }),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  nationalities: nationalitiesSelector(state),
  people: peopleSelector(state),
  genders: gendersSelector(state),
  sports: sportsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...nationalityActions,
      ...personActions,
      ...genderActions,
      ...sportActions,
    },
    dispatch
  );

export default withSnackbar(
  compose(connect(mapStateToProps, mapDispatchToProps))(PeopleList)
);
