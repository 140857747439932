import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart,
  Line,
  Bar,
  LabelList,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Area,
  ResponsiveContainer,
  Cell,
  ReferenceLine,
} from 'recharts';

/**
 * @function CustomLineBarComposedChart
 * @param {array} -- data es un arreglo de objetos con 5 valores: clave es para el eje x y valor para el eje y
 * @returns {ComposedChart}
 */

const RPECustomComposedChart = ({ data }) => {
  const COLORS = ['#3E4095', '#5D60A7'];
  const [hideFrescura, setHideFrescura] = useState(false);
  const [hideUA, setHideUA] = useState(false);
  const [hideCargaAguda, setHideCargaAguda] = useState(false);
  const [hideCargaCronica, setHideCargaCronica] = useState(false);
  const [hideStrain, setHideStrain] = useState(false);
  const [hideMonotonia, setHideMonotonia] = useState(false);
  const [hideCronicaAguda, setHideCronicaAguda] = useState(false);

  const hideOptions = {
    "UA": () => setHideUA(!hideUA),
    "Frescura": () => setHideFrescura(!hideFrescura),
    "Carga aguda": () => setHideCargaAguda(!hideCargaAguda),
    "Carga crónica": () => setHideCargaCronica(!hideCargaCronica),
    "Strain": () => setHideStrain(!hideStrain),
    "Monotonía": () => setHideMonotonia(!hideMonotonia),
    "Crónica/Aguda": () => setHideCronicaAguda(!hideCronicaAguda),
  }

  const CustomizedLabel =(props) =>{
    const { x, y, value } = props;
    return (<text x={x-5} y={y} >{value !== null ? (value < 0 ? `<${value*-1}%` : `>${value}%`) : '' }</text>
    )
  }
  
  const CustomizedAxisTick = props => {
    const { x, y, stroke, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={7}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const toHours = props => {
    return `${props}Hs`;
  };

  return (
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        >
          <ReferenceLine
            y={0}
            yAxisId="left"
            stroke="black"
            strokeDasharray="3 3"
          />
          <Legend verticalAlign="top" height={30} onClick={(data) => {hideOptions[data.payload.name]()} }/>
          <Tooltip />

          <XAxis
            dataKey="date"
            tick={<CustomizedAxisTick />}
            height={70}
            interval={0}
            padding={{ left: 20, right: 20 }}
          />
        { hideUA ? <Bar name="UA" yAxisId="left" fill="#cccccc" ></Bar>
        :  (<Bar
            name="UA"
            yAxisId="left"
            dataKey="value"
            barSize={20}
            fill="#413ea0"
            isAnimationActive={false}
          >
            {data.map((item, index) => {
              const color = index % 2 === 0 ? COLORS[0] : COLORS[1];
              return (<Cell key={item.value} fill={color} />)
            })}
            <LabelList dataKey="diff" position="top" content={<CustomizedLabel />}  />
          </Bar> )
        }
          <YAxis
            dataKey="value"
            yAxisId="left"
            orientation="left"
            domain={[-100, 'auto']}
          />
          <Area
            name="Frescura"
            yAxisId="left"
            type="monotone"
            dataKey="freshnessIndex"
            fill="#22FF4C"
            stroke="#22FF4C"
            legendType="circle"
            hide={hideFrescura}
          />
          <Area
            name="Carga aguda"
            yAxisId="left"
            type="monotone"
            dataKey="acuteLoad"
            fill="#ffc658"
            stroke="#ffc658"
            legendType="circle"
            hide={hideCargaAguda}
          />
          <Area
            name="Carga crónica"
            yAxisId="left"
            type="monotone"
            dataKey="chronicLoad"
            fill="#82ca9d"
            stroke="#82ca9d"
            legendType="circle"
            hide={hideCargaCronica}
          />
          <Line
            name="Strain"
            yAxisId="left"
            type="monotone"
            dataKey="strain"
            strokeWidth={3}
            stroke="#ED1C24"
            hide={hideStrain}
          />

          <YAxis
            dataKey="monotony"
            yAxisId="right"
            orientation="right"
            domain={[0, 4]}
          />
          <Line
            name="Monotonía"
            yAxisId="right"
            type="monotone"
            dataKey="monotony"
            strokeWidth={3}
            stroke="#0000FF"
            hide={hideMonotonia}
          />
          <Line
            name="Crónica/Aguda"
            yAxisId="right"
            type="monotone"
            dataKey="acwr"
            strokeWidth={3}
            stroke="#000000"
            hide={hideCronicaAguda}
          />
        </ComposedChart>
      </ResponsiveContainer>
  );
};

RPECustomComposedChart.defaultProps = {
  data: [],
};

RPECustomComposedChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      player: PropTypes.string,
      date: PropTypes.string,
      value: PropTypes.number,
      chronicLoad: PropTypes.number,
      acuteLoad: PropTypes.number,
      freshnessIndex: PropTypes.number,
      monotony: PropTypes.number,
      strain: PropTypes.number,
      acwr: PropTypes.number,
    })
  ),
};
export default RPECustomComposedChart;
