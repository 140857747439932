import { ANTHROPOMETRIC_DATA_ACTION_TYPES } from './AnthropometricDataActions';

const initialState = {
  allAnthropometricData: [],
  error: '',
};

export default function anthropometricData(state = initialState, action) {
  switch (action.type) {
    case ANTHROPOMETRIC_DATA_ACTION_TYPES.FETCH_ANTHROPOMETRIC_DATA_SUCCESS:
      return Object.assign({}, state, {
        allAnthropometricData: action.payload,
      });
    default:
      return state;
  }
}
