import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import BarChartIcon from '@material-ui/icons/BarChart';
import CardActions from '@material-ui/core/CardActions';
import './skillsCard.css';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
}));

const SoftSkills = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} elevation={3}>
      <CardHeader
        title={title.toUpperCase()}
        subheader={subtitle}
        style={{ textAlign: 'left' }}
        titleTypographyProps={{ variant: 'display1' }}
      />
      <CardActions style={{ justifyContent: 'space-between' }}>
        <div>
          <span className="dot yellow" />
          <span className="dot green" />
          <span className="dot yellow" />
          <span className="dot red" />
          <span className="dot green" />
        </div>
        <div>
          <BarChartIcon fontSize="large" />
        </div>
      </CardActions>
    </Card>
  );
};

SoftSkills.defaultProps = {
  title: '',
  subtitle: '',
};

SoftSkills.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default SoftSkills;
