import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { myTeamsSelector, teamsSelector } from '../../team/TeamSelectors';
import * as teamActions from '../../team/TeamActions';
import * as categoryActions from '../../category/CategoryActions';
import * as sportActions from '../../sport/SportActions';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import PeopleIcon from '@material-ui/icons/People';
import Tooltip from '@material-ui/core/Tooltip';
import BarChartIcon from '@material-ui/icons/BarChart';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
  categorySelect: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  teamsContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignContent: 'flex-start',
  },
  card: {
    width: '300px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '3px',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const SquadList = ({ myTeams, fetchMyTeams, history }) => {
  const classes = useStyles();
  const [fetch, setFetch] = useState(null);

  useEffect(() => {
    fetchMyTeams();
  }, [fetch]);

  const goToDetail = id => {
    history.push(`/squads/${id}`);
  };

  const goToStats = id => {
    history.push(`/squads/${id}/stats`);
  };

  const [mergedTeams, setMergedTeams] = useState([]);

  const STAFF_ROLES = ['physicalStaff', 'coordinator', 'coachingStaff'];

  useEffect(() => {
    if (myTeams && Object.keys(myTeams).length > 0) {
      const tempTeams = [];
      Object.keys(myTeams).forEach(key => {
        myTeams[key].forEach(team => {
          if (tempTeams.filter(t => t.id === team.id).length <= 0) {
            tempTeams.push({ ...team, statsEnabled: STAFF_ROLES.includes(key) });
          } else if (STAFF_ROLES.includes(key)) {
            const index = tempTeams.findIndex(t => t.id === team.id);
            tempTeams[index] = { ...tempTeams[index], statsEnabled: true };
          }
        });
      });
      setMergedTeams(tempTeams);
    }
  }, [myTeams]);

  return (
    <div className={classes.container}>
      <div className={classes.teamsContainer}>
        {mergedTeams &&
        mergedTeams.map(team => {
          return (
            <Card key={team.id} className={classes.card} elevation={3}>
              <CardHeader title={team.name} subheader={team.description}/>
              <CardContent>
                <Typography variant="body2">
                  Deporte: {team.sport.name}
                </Typography>
                <Typography variant="body2">
                  Categoría: {team.category.description}
                </Typography>
                <Typography variant="body2">
                  Fecha: {team.initialDate} al {team.endDate}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Tooltip title="Integrantes">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => goToDetail(team.id)}
                  >
                    <PeopleIcon/>
                  </Button>
                </Tooltip>
                <Tooltip title="Ficha">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!team.statsEnabled}
                    onClick={() => goToStats(team.id)}
                  >
                    <BarChartIcon/>
                  </Button>
                </Tooltip>
              </CardActions>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  teams: teamsSelector(state),
  myTeams: myTeamsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...teamActions, ...categoryActions, ...sportActions },
    dispatch,
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SquadList);
