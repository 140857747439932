import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import { translations } from '../../../const/table-localization';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { teamsSelector } from '../TeamSelectors';
import * as teamActions from '../TeamActions';
import * as categoryActions from '../../category/CategoryActions';
import * as sportActions from '../../sport/SportActions';
import { categoriesSelector } from '../../category/CategorySelectors';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { sportsSelector } from '../../sport/SportSelectors';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
  categorySelect: {
    width: '100%',
  },
  rootField: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const TeamList = ({
  fetchTeams,
  teams,
  fetchCategories,
  categories,
  fetchSports,
  sports,
  addTeam,
  updateTeam,
  deleteTeam,
}) => {
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(5);
  const [fetch, setFetch] = useState(null);

  const tableOptions = {
    search: false,
    sorting: true,
    padding: 'dense',
    actionsColumnIndex: -1,
    grouping: false,
    draggable: false,
    emptyRowsWhenPaging: false,
    pageSize: pageSize,
    paging: false,
    addRowPosition: 'first',
  };

  useEffect(() => {
    fetchTeams();
    fetchCategories();
    fetchSports();
  }, [fetch]);

  const columns = [
    {
      title: 'Nombre',
      field: 'name',
      editComponent: props => (
        <FormControl fullWidth>
          <TextField
            className={classes.rootField}
            placeholder="Nombre *"
            type="text"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '250px',
      },
    },
    {
      title: 'Descripción',
      field: 'description',
      editComponent: props => (
        <FormControl fullWidth>
          <TextField
            placeholder="Descripción *"
            type="text"
            size="small"
            margin="dense"
            required
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={!props.value}
          />
        </FormControl>
      ),
      cellStyle: {
        width: '300px',
      },
    },
    {
      title: 'Categoría',
      field: 'category.id',
      render: rowData => rowData.category.description,
      editComponent: props => {
        return (
          <TextField
            select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            className={classes.categorySelect}
          >
            {categories.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.description}
              </MenuItem>
            ))}
          </TextField>
        );
      },
      cellStyle: {
        width: '200px',
      },
    },
    {
      title: 'Deporte',
      field: 'sport.id',
      render: rowData => rowData.sport.name,
      editComponent: props => {
        return (
          <TextField
            select
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            error={!props.value}
            fullWidth
            className={classes.categorySelect}
          >
            {sports.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </TextField>
        );
      },
      cellStyle: {
        width: '150px',
      },
    },
    {
      title: 'Fecha inicio',
      field: 'initialDate',
      render: rowData => moment(rowData.initialDate).format('DD-MM-YYYY'),
      type: 'date',
      initialEditValue: new Date(),
      cellStyle: {
        width: '150px',
      },
    },
    {
      title: 'Fecha fin',
      field: 'endDate',
      render: rowData => moment(rowData.endDate).format('DD-MM-YYYY'),
      type: 'date',
      initialEditValue: new Date(),
      cellStyle: {
        width: '150px',
      },
    },
  ];

  return (
    <div className={classes.container}>
      <MaterialTable
        title={
          <Typography variant="h5" color="textPrimary" align="center">
            Listado de equipos
          </Typography>
        }
        onChangeRowsPerPage={value => {
          setPageSize(value);
        }}
        columns={columns}
        options={tableOptions}
        localization={translations}
        data={teams}
        actions={[
          {
            onClick: (event, rowData) => {},
            icon: 'list_alt',
            tooltip: 'Ver detalle',
          },
        ]}
        editable={{
          isEditable: rowData => true,
          isDeletable: rowData => true,
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              const {
                name,
                description,
                category,
                sport,
                initialDate,
                endDate,
              } = newData;
              if (
                !name ||
                !description ||
                !category ||
                !sport ||
                !initialDate ||
                !endDate
              ) {
                reject();
              }
              addTeam({
                name,
                description,
                category: category.id,
                sport: sport.id,
                initialDate: moment(initialDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD'),
              }).then(res => {
                fetchTeams();
                resolve();
              });
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const {
                id,
                name,
                description,
                category,
                sport,
                initialDate,
                endDate,
              } = newData;
              if (
                !id ||
                !name ||
                !description ||
                !category ||
                !sport ||
                !initialDate ||
                !endDate
              ) {
                reject();
              } else {
                updateTeam({
                  id,
                  name,
                  description,
                  category: category.id,
                  sport: sport.id,
                  initialDate: moment(initialDate).format('YYYY-MM-DD'),
                  endDate: moment(endDate).format('YYYY-MM-DD'),
                }).then(res => {
                  fetchTeams();
                  resolve();
                });
              }
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              deleteTeam(oldData.id).then(res => {
                fetchTeams();
                resolve();
              });
            }),
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  teams: teamsSelector(state),
  categories: categoriesSelector(state),
  sports: sportsSelector(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...teamActions, ...categoryActions, ...sportActions },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(TeamList);
