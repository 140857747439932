import { fetchMiddleware } from '../../utils/fetchMiddleware';
import { actionErrorHandler } from '../../utils/actionErrorHandler';

export const SPORT_ACTION_TYPES = {
  FETCH_SPORTS_SUCCESS: 'FETCH_SPORTS_SUCCESS',
  SPORT_ERROR: 'SPORT_ERROR',
};

export const fetchSports = () => dispatch => {
  return fetchMiddleware('GET', '/sport/').then(
    json =>
      dispatch({
        type: SPORT_ACTION_TYPES.FETCH_SPORTS_SUCCESS,
        payload: json,
      }),
    error => actionErrorHandler(error, dispatch)
  );
};
