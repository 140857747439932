import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import './skillsCard.css';
import { Typography, createMuiTheme, ThemeProvider } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiCardHeader: {
      // Name of the rule
      title: {
        // Some CSS
        fontWeight: 600,
      },
    },
  },
});

const calculateAge = age => {
  const birthDate = new Date(age);
  const today = new Date();
  const month = today.getMonth();
  const day = today.getDate();
  const year = today.getFullYear();

  today.setDate(day);
  today.setMonth(month);
  today.setFullYear(year);

  return Math.floor((today - birthDate) / (1000 * 60 * 60 * 24) / 365);
};

const PhysicalAttributes = ({
  title,
  subtitle,
  muscleUpIcon,
  weight,
  height,
  muscle,
  age,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Card elevation={2}>
        <CardHeader
          titleTypographyProps={{ variant: 'h6' }}
          title={title.toUpperCase()}
          subheader={subtitle}
          style={{ textAlign: 'center' }}
        />
        <CardActions style={{ justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'flex',
              width: '-webkit-fill-available',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Peso
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {weight}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Masa Muscular
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {muscle}
                  {/* {muscleUpIcon ? (
                    <ArrowUpwardIcon fontSize="small" />
                  ) : (
                    <ArrowDownwardIcon fontSize="small" />
                  )} */}
                </div>
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Altura
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {height}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Edad
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {calculateAge(age)}
              </Typography>
            </div>
          </div>
        </CardActions>
      </Card>
    </ThemeProvider>
  );
};

PhysicalAttributes.defaultProps = {
  muscleUpIcon: false,
  title: '',
  subtitle: '',
  weight: '',
  height: '',
  muscle: '',
  age: '',
};

PhysicalAttributes.propTypes = {
  muscleUpIcon: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  weight: PropTypes.string,
  height: PropTypes.string,
  muscle: PropTypes.string,
  age: PropTypes.number,
};

export default PhysicalAttributes;
